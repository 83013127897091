import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plans"
export default class extends Controller {
  listPlansPlanSelected(event) {
    document.querySelectorAll('.accordion-button-white').forEach(element => {
      if (element.dataset['plansPlanParam'] != event.params['plan']) {
        if (!element.classList.contains('collapsed')) {
          element.click()
        }
      }
    })
  }
}
