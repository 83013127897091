import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

// Connects to data-controller="admin--firmware-graphing"
export default class extends Controller {
  static targets = [ "onlinePanelFirmwareStatsGraph" ]

  connect() {
    fetch('onlinePanelFirmwareStatisticsGraphData',
          { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
      .then(response => response.json())
      .then(responseData => {
        this.firmwareChart = this.buildGraph(
          this.onlinePanelFirmwareStatsGraphTarget,
          responseData,
          ['Online Panels']
        );
      })
      .catch(err => {})
  }

  buildGraph(target, data, labels) {
    return new Chart(
      target,
      {
        type: 'line',
        data: {
          labels: data.map(row => row.version),
          datasets: [
            {
              label: labels[0],
              data: data,
              parsing: {
                xAxisKey: 'version',
                yAxisKey: 'panel_count'
              }
            }
          ]
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        options: {
          locale: i18n.locale
        }
      }
    )
  }
}
