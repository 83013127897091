import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--pending-installers"
export default class extends Controller {
  static targets = [
    "pendingInstallerNoteInstallerInfo", "pendingInstallerNoteInstallerID",
    "pendingInstallerNotesList", "showPendingInstallerNotesInstallerInfo",
    "approvePendingInstallerName", "approveDeniedPendingInstallerName",
    "approvePendingInstallerButton", "approveDeniedPendingInstallerButton",
  ]
  connect() {
  }

  addNote(event) {
    if (this.hasPendingInstallerNoteInstallerInfoTarget) {
      this.pendingInstallerNoteInstallerIDTarget.value = event.params['installerId']
      this.pendingInstallerNoteInstallerInfoTarget.innerHTML = event.params['installerName']
    }
  }

  showPendingInstallerNotes(events) {
    if (this.hasPendingInstallerNotesListTarget) {
      this.showPendingInstallerNotesInstallerInfoTarget.innerHTML = event.params['installerName']
      // TODO: See if we can get the route from a param
      fetch('pendingInstallerNotes/' + event.params['installerId'], { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
        .then(response => response.json())
        .then(responseData => {
          this.pendingInstallerNotesListTarget.querySelectorAll('tr').forEach(element => element.remove())
          var rowPos = 0
          responseData.forEach(note => {
            var row = this.pendingInstallerNotesListTarget.insertRow(rowPos)
            row.insertCell().innerHTML = note.note
            row.insertCell().innerHTML = note.admin_username
            row.insertCell().innerHTML = new Date(note.created).toLocaleString()
            rowPos++
          })
        })
        .catch(err => {})
    }
  }

  approvePendingInstaller(event) {
    if (this.hasApprovePendingInstallerNameTarget) {
      this.approvePendingInstallerNameTarget.innerHTML = event.params['installerName']
      this.approvePendingInstallerButtonTarget.href = '/admin/approveInstaller/' + event.params['installerId']
    }
  }

  approvePreviouslyDeniedInstaller(event) {
    if (this.hasApproveDeniedPendingInstallerNameTarget) {
      this.approveDeniedPendingInstallerNameTarget.innerHTML = event.params['installerName']
      this.approveDeniedPendingInstallerButtonTarget.href = '/admin/approveInstaller/' + event.params['installerId']
    }
  }
}
