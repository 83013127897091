import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import 'chartjs-adapter-luxon'
import zoomPlugin from 'chartjs-plugin-zoom'
Chart.register(zoomPlugin);

// Connects to data-controller="admin--sia-graphing"
export default class extends Controller {
  static values = {
    graphDuration: Number,
    connectionDataEndpoint: String,
    connectionGraphNames: Array,
    noConnectionData: String,
  }
  static targets = [
    "connectionGraph", "connectionGraphHolder"
  ]

  rebuildGraphsWithNewDuration(event) {
    document.querySelectorAll('.btn-outline-primary.active').forEach(element => element.classList.remove('active'))
    event.target.classList.add('active')
    this.connectionDataEndpointValue = event.params['connectionDataEndpoint']
    this.graphDurationValue = event.params['graphDuration']
    this.refreshGraph()
  }

  connectionGraphTargetConnected() {
    this.refreshGraph();
  }

  resetChartZoom() {
    if (!this.connectionChart) {
      return;
    }
    this.connectionChart.resetZoom();
  }

  refreshGraph() {
    fetch(this.connectionDataEndpointValue,
          { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
      .then(response => response.json())
      .then(responseData => {
        if (!responseData) {
          var div = document.createElement('div')
          var h3 = document.createElement('h3')
          div.classList.add('text-center')
          h3.innerHTML = this.noConnectionDataValue
          div.appendChild(h3)
          this.connectionGraphHolderTarget.appendChild(div);
          this.connectionChart.destroy();
          this.connectionChart = null;
          return;
        }
        if (this.connectionChart) {
          this.refreshChartData(this.connectionChart, responseData);
        } else {
          this.connectionChart = this.buildGraph(
            this.connectionGraphHolderTarget,
            this.connectionGraphTarget,
            [this.connectionGraphNamesValue],
            responseData,
            'coral',
            'orange'
          );
        }
      })
      .catch(err => { })
  }

  refreshChartData(chart, data) {
    var firstDate = new Date(Date.now() - (this.graphDurationValue * 60 * 1000));
    chart.data.datasets[0].data = data;
    chart.data.labels = data.map(row => new Date(row.created));
    chart.options.scales.x.min = firstDate;
    chart.update();
  }

  buildGraph(holder, target, labels, signalData, signalDataColour, signalDataOutlineColour) {
    holder.childNodes.forEach(child => {
      if (child.nodeName != 'CANVAS') {
        holder.removeChild(child)
      }
    });
    return this.buildLineGraph(target, signalData, labels, signalDataColour, signalDataOutlineColour);
  }

  buildLineGraph(target, data, labels, signalDataColour, signalDataOutlineColour) {
    var firstDate = new Date(Date.now() - (this.graphDurationValue * 60 * 1000));
    return new Chart(
      target,
      {
        type: 'line',
        data: {
          labels: data.map(row => new Date(row.poll_time)),
          datasets: [
            {
              label: labels[0],
              data: data,
              parsing: {
                xAxisKey: 'created',
                yAxisKey: 'status'
              },
              spanGaps: false,
              borderColor: signalDataColour,
              backgroundColor: signalDataOutlineColour,
              tension: 0
            }
          ]
        },
        options: {
          locale: i18n.locale,
          scales: {
            x: {
              min: firstDate,
              max: new Date(),
              type: 'time',
              ticks: {
                maxRotation: 0,
                autoskip: false,
                major: {
                  enabled: true
                },
                font: function(context) {
                  if (context.tick && context.tick.major) {
                    return {
                      weight: 'bold',
                    };
                  }
                }
              }
            },
            y: {
              min: 0,
              max: 2
            }
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
                modifierKey: 'ctrl',
              },
              zoom: {
                drag: {
                  enabled: true
                },
                mode: 'x',
              },
            }
          },
        }
      }
    )
  }
}
