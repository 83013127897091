import { Application } from '@hotwired/stimulus';
import { I18n } from 'i18n-js';
import LocalTime from 'local-time';
import jquery from 'jquery';
import * as bootstrap from 'bootstrap';

const application = Application.start();

// Fetch user locale from html#lang.
// This value is being set on `app/views/layouts/application.html.erb` and
// is inferred from `ACCEPT-LANGUAGE` header.
const userLocale = document.documentElement.lang;
export const i18n = new I18n();
//i18n.store(translations);
i18n.defaultLocale = "en";
i18n.enableFallback = true;
i18n.locale = userLocale;

// Configure Stimulus development experience
application.debug = false;
window.Stimulus   = application;
window.i18n = i18n;
window.LocalTime = LocalTime;
window.jQuery = jquery;
window.$ = jquery;
LocalTime.start();

export { application }
