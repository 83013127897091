import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

// Connects to data-controller="admin--online-status-graphing"
export default class extends Controller {
  static targets = [
    "onlineStatsGraph",
    "onlineTypesSplitStatsGraph",
    "onlineTypesCellularBreakdownStatsGraph",
    "ethernetOnlineStatsGraph",
    "wifiOnlineStatsGraph",
    "cellularOnlineStatsGraph",
    "ethernetAndCellularEthernetOnlineStatsGraph",
    "ethernetAndCellularCellularOnlineStatsGraph",
    "ethernetAndCellularBothOnlineStatsGraph",
    "wifiAndCellularWifiOnlineStatsGraph",
    "wifiAndCellularCellularOnlineStatsGraph",
    "wifiAndCellularBothOnlineStatsGraph",
  ]

  connect() {
    fetch('panelsOnlineStatisticsGraphData',
          { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
      .then(response => response.json())
      .then(responseData => {
        this.onlineStatsChart = this.buildGraph(
          this.onlineStatsGraphTarget,
          [responseData['online'], responseData['offline'], responseData['pollfail']],
          ['Online Panels', 'Offline Panels', 'Poll Fail Panels'],
        );
        this.onlineTypesSplitStatsChart = this.buildGraph(
          this.onlineTypesSplitStatsGraphTarget,
          [responseData['ethernet_total'], responseData['wifi_total'],
           responseData['cellular_total'],
           responseData['ethernet_and_public_cellular_total'] + 
            responseData['ethernet_and_3g_myalarm_cellular_total'] +
            responseData['ethernet_and_myalarm_cellular_total'],
           responseData['wifi_and_public_cellular_total'] +
             responseData['wifi_and_3g_myalarm_cellular_total'] +
             responseData['wifi_and_myalarm_cellular_total']],
          ['Ethernet Panels', 'WiFi Panels', 'Cellular Panels',
           'Ethernet and Cellular Panels', 'WiFi and Cellular Panels'],
        );
        this.onlineTypesCellularBreakdownStatsChart = this.buildGraph(
          this.onlineTypesCellularBreakdownStatsGraphTarget,
          [responseData['public_cellular_total'], responseData['3g_myalarm_cellular_total'], responseData['myalarm_cellular_total']],
          ['Public Cellular Panels', '3G MyAlarm Cellular Panels', 'MyAlarm Cellular Panels'],
        );
        this.ethernetStatsChart = this.buildGraph(
          this.ethernetOnlineStatsGraphTarget,
          [responseData['ethernet_online'], responseData['ethernet_offline'], responseData['ethernet_pollfail']],
          ['Ethernet Online Panels', 'Ethernet Offline Panels', 'Ethernet Poll Fail Panels'],
        );
        this.wifiStatsChart = this.buildGraph(
          this.wifiOnlineStatsGraphTarget,
          [responseData['wifi_online'], responseData['wifi_offline'], responseData['wifi_pollfail']],
          ['WiFi Online Panels', 'WiFi Offline Panels', 'WiFi Poll Fail Panels'],
        );
        this.cellularStatsChart = this.buildGraph(
          this.cellularOnlineStatsGraphTarget,
          [responseData['cellular_online'], responseData['cellular_offline'], responseData['cellular_pollfail']],
          ['Cellular Online Panels', 'Cellular Offline Panels', 'Cellular Poll Fail Panels'],
        );
        this.ethernetAndCellularEthernetStatsChart = this.buildGraph(
          this.ethernetAndCellularEthernetOnlineStatsGraphTarget,
          [
            responseData['ethernet_and_public_cellular_eth_online'] +
            responseData['ethernet_and_3g_myalarm_cellular_eth_online'] +
            responseData['ethernet_and_myalarm_cellular_eth_online'],
            responseData['ethernet_and_public_cellular_eth_offline'] +
            responseData['ethernet_and_3g_myalarm_cellular_eth_offline'] +
            responseData['ethernet_and_myalarm_cellular_eth_offline'],
            responseData['ethernet_and_public_cellular_eth_pollfail'] +
            responseData['ethernet_and_3g_myalarm_cellular_eth_pollfail'] +
            responseData['ethernet_and_myalarm_cellular_eth_pollfail']
          ],
          ['Ethernet and Cellular - Ethernet Path Online Panels', 'Ethernet and Cellular - Ethernet Path Offline Panels', 'Ethernet and Cellular - Ethernet Path Poll Fail Panels'],
        );
        this.ethernetAndCellularCellularStatsChart = this.buildGraph(
          this.ethernetAndCellularCellularOnlineStatsGraphTarget,
          [
            responseData['ethernet_and_public_cellular_cellular_online'] +
            responseData['ethernet_and_3g_myalarm_cellular_cellular_online'] +
            responseData['ethernet_and_myalarm_cellular_cellular_online'],
            responseData['ethernet_and_public_cellular_cellular_offline'] +
            responseData['ethernet_and_3g_myalarm_cellular_cellular_offline'] +
            responseData['ethernet_and_myalarm_cellular_cellular_offline'],
            responseData['ethernet_and_public_cellular_cellular_pollfail'] +
            responseData['ethernet_and_3g_myalarm_cellular_cellular_pollfail'] +
            responseData['ethernet_and_myalarm_cellular_cellular_pollfail']
          ],
          ['Ethernet and Cellular - Cellular Path Online Panels', 'Ethernet and Cellular - Cellular Path Offline Panels', 'Ethernet and Cellular - Cellular Path Poll Fail Panels'],
        );
        this.ethernetAndCellularBothStatsChart = this.buildGraph(
          this.ethernetAndCellularBothOnlineStatsGraphTarget,
          [
            responseData['ethernet_and_public_cellular_both_online'] +
            responseData['ethernet_and_3g_myalarm_cellular_both_online'] +
            responseData['ethernet_and_myalarm_cellular_both_online'],
            responseData['ethernet_and_public_cellular_both_offline'] +
            responseData['ethernet_and_3g_myalarm_cellular_both_offline'] +
            responseData['ethernet_and_myalarm_cellular_both_offline'],
            responseData['ethernet_and_public_cellular_both_pollfail'] +
            responseData['ethernet_and_3g_myalarm_cellular_both_pollfail'] +
            responseData['ethernet_and_myalarm_cellular_both_pollfail']
          ],
          ['Ethernet and Cellular - Both Online Panels', 'Ethernet and Cellular - Both Offline Panels', 'Ethernet and Cellular - Both Poll Fail Panels'],
        );
        this.wifiAndCellularEthernetStatsChart = this.buildGraph(
          this.wifiAndCellularWifiOnlineStatsGraphTarget,
          [
            responseData['wifi_and_public_cellular_eth_online'] +
            responseData['wifi_and_myalarm_cellular_eth_online'] +
            responseData['wifi_and_myalarm_cellular_eth_online'],
            responseData['wifi_and_public_cellular_eth_offline'] +
            responseData['wifi_and_3g_myalarm_cellular_eth_offline'] +
            responseData['wifi_and_myalarm_cellular_eth_offline'],
            responseData['wifi_and_public_cellular_eth_pollfail'] +
            responseData['wifi_and_3g_myalarm_cellular_eth_pollfail'] +
            responseData['wifi_and_myalarm_cellular_eth_pollfail']
          ],
          ['WiFi and Cellular - Ethernet Path Online Panels', 'WiFi and Cellular - Ethernet Path Offline Panels', 'WiFi and Cellular - Ethernet Path Poll Fail Panels'],
        );
        this.wifiAndCellularCellularStatsChart = this.buildGraph(
          this.wifiAndCellularCellularOnlineStatsGraphTarget,
          [
            responseData['wifi_and_public_cellular_cellular_online'] +
            responseData['wifi_and_3g_myalarm_cellular_cellular_online'] +
            responseData['wifi_and_myalarm_cellular_cellular_online'],
            responseData['wifi_and_public_cellular_cellular_offline'] +
            responseData['wifi_and_3g_myalarm_cellular_cellular_offline'] +
            responseData['wifi_and_myalarm_cellular_cellular_offline'],
            responseData['wifi_and_public_cellular_cellular_pollfail'] +
            responseData['wifi_and_3g_myalarm_cellular_cellular_pollfail'] +
            responseData['wifi_and_myalarm_cellular_cellular_pollfail']
          ],
          ['WiFi and Cellular - Cellular Path Online Panels', 'WiFi and Cellular - Cellular Path Offline Panels', 'WiFi and Cellular - Cellular Path Poll Fail Panels'],
        );
        this.wifiAndCellularBothStatsChart = this.buildGraph(
          this.wifiAndCellularBothOnlineStatsGraphTarget,
          [
            responseData['wifi_and_public_cellular_both_online'] +
            responseData['wifi_and_3g_myalarm_cellular_both_online'] +
            responseData['wifi_and_myalarm_cellular_both_online'],
            responseData['wifi_and_public_cellular_both_offline'] +
            responseData['wifi_and_3g_myalarm_cellular_both_offline'] +
            responseData['wifi_and_myalarm_cellular_both_offline'],
            responseData['wifi_and_public_cellular_both_pollfail'] +
            responseData['wifi_and_3g_myalarm_cellular_both_pollfail'] +
            responseData['wifi_and_myalarm_cellular_both_pollfail']
          ],
          ['WiFi and Cellular - Both Online Panels', 'WiFi and Cellular - Both Offline Panels', 'WiFi and Cellular - Both Poll Fail Panels'],
        );
      })
      .catch(err => { console.log(err); })
  }

  buildGraph(target, data, labels) {
    return new Chart(
      target,
      {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              hoverOffset: 4
            }
          ]
        }
      }
    )
  }
}
