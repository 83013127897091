import { Controller } from "@hotwired/stimulus"
import braintree from 'braintree-web'
import paypal from 'paypal-checkout'

// Connects to data-controller="payments"
export default class extends Controller {
  connect() {
    this.fetchClientToken()
  }

  fetchClientToken() {
    fetch('/paymentsClientToken', { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
      .then(response => response.json())
      .then(responseData => this.setupPaymentPage(responseData['client_token']))
      .catch(err => {})
  }

  setupPaymentPage(clientToken) {
    braintree.client.create({
      authorization: clientToken,
    }).then(client => {
      this.setupPaypal(client)
      this.setupHostedFields(client)
    });
  }

  setupPaypal(client) {
    braintree.paypalCheckout.create({
      client: client
    }).then(paypalCheckoutInstance => {
      paypal.Button.render({
        env: 'production', // or 'sandbox'
        locale: $('#paypal-button').data('invoice_locale'),
        commit: true,
        payment: function () {
          return paypalCheckoutInstance.createPayment({
            flow: 'checkout',
            amount: $('#paypal-button').data('invoice_amount'),
            currency: $('#paypal-button').data('invoice_currency'),
            intent: 'sale'
          });
        },
        onAuthorize: function (data, actions) {
          return paypalCheckoutInstance.tokenizePayment(data, function (err, payload) {
            $('#payment_payment_method_nonce').val(payload.nonce);
            $('#payment_payment_method_type').val('PayPal');
            document.getElementById('payment-form').submit();
          });
        },
        onCancel: function (data) {
          // console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
        },
        onError: function (err) {
          console.error('Checkout Error');
          // console.error('checkout.js error', err);
        }
      }, '#paypal-button').then(function () {
      // The PayPal button will be rendered in an html element with the id
      // `paypal-button`. This function will be called when the PayPal button
      // is set up and ready to be used.
      });
    });
  }

  setupHostedFields(client) {
    braintree.hostedFields.create({
      client: client,
      styles: {
        'input': {
          'font-size': '16pt',
          'color': '#3A3A3A',
          'line-height': '50px',
          'font-family': 'monospace'
        },
        ':focus': {
          'color': 'blue'
        },
        '.valid': {
          'color': 'green'
        },
        '.invalid': {
          'color': 'red'
        },
        '@media screen and (max-width: 700px)': {
          'input': {
            'font-size': '14pt'
          }
        }
      },
      fields: {
        number: {
          selector: '#card-number',
          placeholder: 'Card Number',
          rejectUnsupportedCards: true
        },
        cvv: {
          selector: '#security-code',
          placeholder: 'CVV'
        },
        expirationMonth: {
          selector: '#expiration-month',
          placeholder: 'MM'
        },
        expirationYear: {
          selector: '#expiration-year',
          placeholder: 'YYYY'
        },
      }
    }).then(hostedFields => {
      hostedFields.on('cardTypeChange', function (event) {
        if (event.cards.length === 1) {
          var iconType = document.getElementsByClassName(`icon-type-${event.cards[0].type}`)[0];
          if (iconType) {
            iconType.className = `icon icon-type icon-type-${event.cards[0].type}`;
            if (event.cards[0].type === 'visa') {
              window.validCard = true;
              let masterCardIcon = document.getElementsByClassName('icon-type-master-card')[0];
              let amexCardIcon = document.getElementsByClassName('icon-type-american-express')[0];
              if (masterCardIcon) {
                masterCardIcon.className += ' icon-type-master-card-disabled';
              }
              if (amexCardIcon) {
                amexCardIcon.className += ' icon-type-american-express-disabled';
              }
            } else if (event.cards[0].type === 'master-card') {
              window.validCard = true;
              let visaCardIcon = document.getElementsByClassName('icon-type-visa')[0];
              let amexCardIcon = document.getElementsByClassName('icon-type-american-express')[0];
              if (visaCardIcon) {
                visaCardIcon.className += ' icon-type-visa-disabled';
              }
              if (amexCardIcon) {
                amexCardIcon.className += ' icon-type-american-express-disabled';
              }
            } else if (event.cards[0].type === 'american-express') {
              window.validCard = true;
              let masterCardIcon = document.getElementsByClassName('icon-type-master-card')[0];
              let visaCardIcon = document.getElementsByClassName('icon-type-visa')[0];
              if (masterCardIcon) {
                masterCardIcon.className += ' icon-type-master-card-disabled';
              }
              if (visaCardIcon) {
                visaCardIcon.className += ' icon-type-visa-disabled';
              }
            }
          } else {
            window.validCard = false;
            let masterCardIcon = document.getElementsByClassName('icon-type-master-card')[0];
            let amexCardIcon = document.getElementsByClassName('icon-type-american-express')[0];
            let visaCardIcon = document.getElementsByClassName('icon-type-visa')[0];
            if (masterCardIcon) {
              masterCardIcon.className += ' icon-type-master-card-disabled';
            }
            if (amexCardIcon) {
              amexCardIcon.className += ' icon-type-american-express-disabled';
            }
            if (visaCardIcon) {
              visaCardIcon.className += ' icon-type-visa-disabled';
            }
          }
        } else {
          window.validCard = false;
          let masterCardIcon = document.getElementsByClassName('icon-type-master-card')[0];
          let amexCardIcon = document.getElementsByClassName('icon-type-american-express')[0];
          let visaCardIcon = document.getElementsByClassName('icon-type-visa')[0];
          if (masterCardIcon) {
            masterCardIcon.className = 'icon icon-type icon-type-master-card';
          }
          if (amexCardIcon) {
            amexCardIcon.className = 'icon icon-type icon-type-american-express';
          }
          if (visaCardIcon) {
            visaCardIcon.className = 'icon icon-type icon-type-visa';
          }
        }
      });
      hostedFields.on('validityChange', function (event) {
        var field = event.fields[event.emittedBy];

        if (event.emittedBy === 'number') {
          if (field.isValid) {
            window.cardComplete = true;
          } else {
            window.cardComplete = false;
          }
        } else if (event.emittedBy === 'cvv') {
          if (field.isValid) {
            window.cvvComplete = true;
          } else {
            window.cvvComplete = false;
          }
        } else if (event.emittedBy === 'expirationMonth') {
          if (field.isValid) {
            window.expirationMonth = true;
          } else {
            window.expirationMonth = false;
          }
        } else if (event.emittedBy === 'expirationYear') {
          if (field.isValid) {
            window.expirationYear = true;
          } else {
            window.expirationYear = false;
          }
        }
        if (window.validCard && window.cardComplete && window.cvvComplete && window.expirationMonth && window.expirationYear) {
          $('#payInvoiceBtn').prop('disabled', false);
        } else {
          $('#payInvoiceBtn').prop('disabled', true);
        }
      });
      document.getElementById('payInvoiceBtn').addEventListener('click', function (event) {
        $('#cover').removeClass('d-none');
        hostedFields.tokenize().then(payload => {
          $('#payment_payment_method_nonce').val(payload.nonce);
          $('#payment_payment_method_type').val('Credit Card');
          $('#payment_payment_method_card_type').val(payload.details.cardType);
          document.getElementById('payment-form').submit();
          return true;
        });
      }, false);
    });
  }

}
