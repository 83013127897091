import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="sites"
export default class extends Controller {
  static targets = [ "changeLanguageForm" ]

  connect() {
      this.conformInputBoxLabelWidth();
      this.setupSortableTable()
      this.setupToolTips()
  }

  conformInputBoxLabelWidth() {
    // Set all input box label widths that are not inside a modal to the width of the longest label on the page
    if(document.querySelector(
      '.input-group > .input-group-prepend > .input-group-text:not(.modal .input-group > .input-group-prepend > .input-group-text)'
    )){
      var collapsed = document.querySelectorAll('.collapse');
      collapsed.forEach(element => { element.style.display = 'block' });
      var dNone = document.querySelectorAll('.d-none');
      dNone.forEach(element => { element.classList.remove('d-none') })
      document.querySelectorAll(
        '.input-group > .input-group-prepend > .input-group-text:not(.modal .input-group > .input-group-prepend > .input-group-text)'
      ).forEach(element => element.style.width = '');

      var mWidth = 0;
      var inputs = document.querySelectorAll(
        '.input-group > .input-group-prepend > .input-group-text:not(.modal .input-group > .input-group-prepend > .input-group-text)'
      )
      inputs.forEach(element => {
        var tWidth = element.offsetWidth;
        if (Number(mWidth) < Number(tWidth)) {
          mWidth = tWidth;
        }
      });

      document.querySelectorAll(
        '.input-group > .input-group-prepend > .input-group-text:not(.no-resize .modal .input-group > .input-group-prepend > .input-group-text)'
      ).forEach(element => element.style.width = mWidth + 'px');
      document.querySelectorAll(
        '.input-group > .input-group-prepend > .input-group-text:not(.modal .input-group > .input-group-prepend > .input-group-text'
      ).forEach(element => element.style.display = 'block');
      document.querySelectorAll(
        '.input-group > .input-group-prepend > .input-group-text:not(.modal .input-group > .input-group-prepend > .input-group-text'
      ).forEach(element => element.style.textAlign = 'right');
      collapsed.forEach(element => { element.style.display = '' });
      dNone.forEach(element => { element.classList.add('d-none') })
    }

    // Set all input box label widths inside each modal to the width of the longest label in that modal
    if(document.querySelector('.input-group > .input-group-prepend > .input-group-text')){

        var modals = document.querySelectorAll('.modal');

        modals.forEach(modal => {

          var collapsed = modal.querySelectorAll('.collapse');
          collapsed.forEach(element => { element.style.display = 'block' });
          var dNone = modal.querySelectorAll('.d-none');
          dNone.forEach(element => { element.classList.remove('d-none') });

          modal.style.display = 'block';

          modal.querySelectorAll('.input-group > .input-group-prepend > .input-group-text').forEach(element => element.style.width = '');
  
          var mWidth = 0;
          var inputs = document.querySelectorAll('.input-group > .input-group-prepend > .input-group-text')
          inputs.forEach(element => {
            var tWidth = element.offsetWidth;
            if (Number(mWidth) < Number(tWidth)) {
              mWidth = tWidth;
            }
          });
    
          modal.querySelectorAll('.input-group > .input-group-prepend > .input-group-text:not(.no-resize)')
            .forEach(element => element.style.width = mWidth + 'px');
          modal.querySelectorAll('.input-group > .input-group-prepend > .input-group-text')
            .forEach(element => element.style.display = 'block');
          modal.querySelectorAll('.input-group > .input-group-prepend > .input-group-text')
            .forEach(element => element.style.textAlign = 'right');
          collapsed.forEach(element => { element.style.display = '' });
          dNone.forEach(element => { element.classList.add('d-none') })
          modal.style.display = 'none';
        })
      }
  }

  setupSortableTable() {
    if (document.querySelector('table[data-sortable]')) {
      // These double falses are to trick sortable into thinking it needs to initiate on turbo load
      sorttable.init.done = false;
      sorttable.init();
    }
  }

  setupToolTips() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  changeLanguage(event) {
    if (this.hasChangeLanguageFormTarget) {
      this.changeLanguageFormTarget.submit();
    }
  }
}
