import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
import * as datepicker from 'bootstrap-datepicker' // TODO: Find a date picker alternative that isnt jQuery
import AutoNumeric from 'autonumeric'

// Connects to data-controller="admin--invoicing"
export default class extends Controller {
  static targets = [
    "invoicePaymentAmount", "invoicePaymentDate",
    "invoicePaymentComment", "confirmPaymentModal", "confirmAdditionalPaymentModal",
    "confirmPaymentAmount", "confirmPaymentDate", "confirmPaymentComment",
    "confirmPaymentAmountInput", "confirmPaymentDateInput", "confirmPaymentCommentInput",
    "confirmAdditionalPaymentAmount", "confirmAdditionalPaymentDate", "confirmAdditionalPaymentComment",
    "confirmAdditionalPaymentAmountInput", "confirmAdditionalPaymentDateInput", "confirmAdditionalPaymentCommentInput",
    "removeSiteFromInvoiceModal", "removeSiteFromInvoiceDetails",
    "removeSiteFromInvoiceSerialInput", "removeSiteFromInvoiceResetSiteInput"
  ]

  olderInvoiceYears = [ "year_2016", "year_2017", "year_2018",
                        "year_2019" ]

  connect() {
    if (this.hasInvoicePaymentAmountTarget) {
      if (!AutoNumeric.test(this.invoicePaymentAmountTarget)) {
        const options = {
          digitGroupSeparator        : ',',
          decimalCharacter           : '.',
          decimalCharacterAlternative: '.',
          currencySymbol             : '$',
          currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
          roundingMethod             : AutoNumeric.options.roundingMethod.halfUpSymmetric,
          minimumValue : 0
        };
        new AutoNumeric(this.invoicePaymentAmountTarget, options);
      }
    }
    if (this.hasInvoicePaymentDateTarget) {
      $('.date').datepicker({
          format: "dd/mm/yyyy",
          endDate: "Today",
          autoclose: true,
          todayBtn: "linked",
          todayHighlight: true
        })
    }
  }

  confirmPayment(event) {
    this.confirmPaymentAmountTarget.innerHTML = this.invoicePaymentAmountTarget.value
    this.confirmPaymentDateTarget.innerHTML = this.invoicePaymentDateTarget.value
    this.confirmPaymentCommentTarget.innerHTML = this.invoicePaymentCommentTarget.value
    this.confirmPaymentAmountInputTarget.value = this.invoicePaymentAmountTarget.value
    this.confirmPaymentDateInputTarget.value = this.invoicePaymentDateTarget.value
    this.confirmPaymentCommentInputTarget.value = this.invoicePaymentCommentTarget.value
    if (!this.confirmPaymentModal) {
      this.confirmPaymentModal = new bootstrap.Modal(this.confirmPaymentModalTarget, { keyboard: false });
    }
    if (this.confirmPaymentModal) {
      this.confirmPaymentModal.show()
    }
  }

  confirmAdditionalPayment(event) {
    this.confirmAdditionalPaymentAmountTarget.innerHTML = this.invoicePaymentAmountTarget.value
    this.confirmAdditionalPaymentDateTarget.innerHTML = this.invoicePaymentDateTarget.value
    this.confirmAdditionalPaymentCommentTarget.innerHTML = this.invoicePaymentCommentTarget.value
    this.confirmAdditionalPaymentAmountInputTarget.value = this.invoicePaymentAmountTarget.value
    this.confirmAdditionalPaymentDateInputTarget.value = this.invoicePaymentDateTarget.value
    this.confirmAdditionalPaymentCommentInputTarget.value = this.invoicePaymentCommentTarget.value
    if (!this.confirmAdditionalPaymentModal) {
      this.confirmAdditionalPaymentModal = new bootstrap.Modal(this.confirmAdditionalPaymentModalTarget, { keyboard: false });
    }
    if (this.confirmAdditionalPaymentModal) {
      this.confirmAdditionalPaymentModal.show()
    }
  }

  deleteSiteFromInvoice(event) {
    this.removeSiteFromInvoiceDetailsTarget.innerHTML = event.params['siteSerial'] + ' - ' + event.params['sitePlan']
    this.removeSiteFromInvoiceSerialInputTarget.value = event.params['siteSerial']
    if (!this.removeSiteFromInvoiceModal) {
      this.removeSiteFromInvoiceModal = new bootstrap.Modal(this.removeSiteFromInvoiceModalTarget, { keyboard: false });
    }
    if (this.removeSiteFromInvoiceModal) {
      this.removeSiteFromInvoiceModal.show()
    }
  }

  resetSitePlanSettings(event) {
    this.removeSiteFromInvoiceResetSiteInputTarget.value = event.target.checked
  }

  toggleOlderInvoiceYears(event) {
    Array.from(document.querySelectorAll('div[id^="year_"]')).filter(yearDiv => {
      if (this.olderInvoiceYears.includes(yearDiv.id)) {
        yearDiv.classList.toggle('d-none')
      }
    })
  }
}
