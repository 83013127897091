import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import utilsScript from 'intl-tel-input/build/js/utils'
import * as bootstrap from "bootstrap"

// Connects to data-controller="reporting"
export default class extends Controller {
  static values = {
    templateTypes: Array,
    templates: Array,
    profiles: Array,
    language: String,
  };
  static targets = [
    "templateType", "deleteReportingTemplateButton", "deleteReportingTemplateModal", "greekContactIdWarningModal",
    "newProfileForm", "submitForm", "submitUpdateTemplate",
    // contact id
    "contactIdOptions", "contactIdPrimaryPhone", "contactIdPrimaryDestination", "contactIdSecondaryPhone", "contactIdSecondaryDestination",
    "phone", "secondaryPhone", "contactIdTypeChoice",
    // csv
    "csvOptions", "csvPrimaryIpAddress", "csvPrimaryIpPort", "csvSecondaryIpAddress",
    "csvSecondaryIpPort", "csvProtocol", "csvProtocolChoice", "csvEncryption",
    "csvEncryption", "csvUsername", "csvPassword",
    // sia
    "siaOptions", "siaPrimaryIpAddress", "siaPrimaryIpPort", "siaSecondaryIpAddress",
    "siaSecondaryIpPort", "siaProtocol", "siaProtocolChoice", "siaEncryption", "siaMessageFormat",
    "siaReceiverNumber", "siaAccountPrefix", "siaAddEventText", "siaAddTimestamp",
    "siaAddEventTextHolder", "siaAddTimestampHolder",
    // myalarm sia
    "myAlarmSiaOptions", "myAlarmSiaBaseStationId", "myAlarmSiaIdentification",
    "myAlarmSiaMessageFormat", "myAlarmSiaReceiverNumber", "myAlarmSiaAccountPrefix",
    "myAlarmSiaAddEventTextHolder", "myAlarmSiaAddEventText", "myAlarmSiaBaseStationIdHolder",
    //  mvr
    "mvrOptions", "mvrBaseStationId", "mvrIdentification",
    "mvrReceiverNumber", "mvrLineNumber",
    "mvrBaseStationIdHolder",
    // profile
    "profileTemplate", "profileTemplateType", "profileTemplateTypeFormInput",
    "accountNumberHolder", "accountNumber",
    "submitProfile", "accountNumberInUseAlert", "accountNumberInUsePanel",
    "viewReportingProfilePage", "deleteReportingTemplateProfileButton",
    "contactIdSecondaryPhoneHolder",
  ];

  showDeleteTemplateModal(event) {
    if (this.hasDeleteReportingTemplateModalTarget) {
      this.deleteReportingTemplateButtonTarget.href = event.params['deleteRoute']
      if (!this.deleteReportingTemplateModal) {
        this.deleteReportingTemplateModal = new bootstrap.Modal(this.deleteReportingTemplateModalTarget, { keyboard: false });
      }
      if (this.deleteReportingTemplateModal) {
        this.deleteReportingTemplateModal.show()
      }
    }
  }

  checkGreekNewReportingProfile(event) {
    if (this.selectedTemplate && this.selectedTemplate.type == this.templateTypesValue[0]) {
      if (!this.greekContactIdWarningModal) {
        this.greekContactIdWarningModal = new bootstrap.Modal(this.greekContactIdWarningModalTarget, { keyboard: false });
      }
      if (this.greekContactIdWarningModal) {
        this.greekContactIdWarningModal.show();
      }
    } else {
      this.submitForm(event);
    }
  }

  submitForm(event) {
    this.newProfileFormTarget.submit()
  }

  viewReportingProfilePageTargetConnected(target) {
    var template = this.findSelectedTemplate(this.profileTemplateTarget.value)
    if (template) {
      this.selectedTemplate = template
      this.checkValidAccountNumber()
    }
  }

  templateTypeChanged(event) {
    // HARD LOOKUP TO B2B_REPORTING_TYPES
    if (this.hasSubmitUpdateTemplateFormTarget) {
      this.submitUpdateTemplateFormTarget.disabled = false;
    }
    this.submitFormTarget.disabled = false;
    if (this.getSelectedTemplateType() == 'cid') {
      this.showContactId()
      this.hideCsv()
      this.hideSia()
      this.hideMyAlarmSia()
      this.hideMvr()
      if (this.hasSubmitUpdateTemplateFormTarget) {
        this.submitUpdateTemplateFormTarget.disabled = true;
      }
      this.submitFormTarget.disabled = true;
    } else if (this.getSelectedTemplateType() == 'csv') {
      this.hideContactId()
      this.showCsv()
      this.hideSia()
      this.hideMyAlarmSia()
      this.hideMvr()
    } else if (this.getSelectedTemplateType() == 'sia') {
      this.hideContactId()
      this.hideCsv()
      this.showSia()
      this.hideMyAlarmSia()
      this.hideMvr()
    } else if (this.getSelectedTemplateType() == 'mcdi') {
      this.hideContactId()
      this.hideCsv()
      this.hideSia()
      this.showMyAlarmSia()
      this.hideMvr()
    } else if (this.getSelectedTemplateType() == 'mvr') {
      this.hideContactId()
      this.hideCsv()
      this.hideSia()
      this.hideMyAlarmSia()
      this.showMvr()
    } else {
      this.hideContactId()
      this.hideCsv()
      this.hideSia()
      this.hideMyAlarmSia()
      this.hideMvr()
    }
  }

  findSelectedTemplate(templateId) {
    return this.templatesValue.find(template => {
      return template.id == templateId
    })
  }

  checkIfAccountNumberInUse() {
    var trimmed_profiles = this.profilesValue.filter(profile => {
      return profile.template == this.selectedTemplate.id
    });
    if (trimmed_profiles) {
      var found_profile = trimmed_profiles.find(profile => {
        return profile.account == this.accountNumberTarget.value
      })
      if (found_profile) {
        this.accountNumberTarget.classList.remove('alert-success')
        this.accountNumberTarget.classList.remove('bg-verified')
        this.accountNumberTarget.classList.add('alert-danger')
        this.accountNumberTarget.classList.add('bg-unverified')
        this.submitProfileTarget.disabled = true
        this.accountNumberInUsePanelTarget.text = found_profile.serial
        this.accountNumberInUsePanelTarget.href = '/reportingProfile/' + found_profile.serial
        this.accountNumberInUseAlertTarget.classList.remove('d-none')
        return true
      } else {
        this.submitProfileTarget.disabled = false
        this.accountNumberInUseAlertTarget.classList.add('d-none')
      }
    }
    return false
  }

  profileTemplateChanged(event) {
    var template = this.findSelectedTemplate(this.profileTemplateTarget.value)
    if (template) {
      this.selectedTemplate = template
      this.hideContactId()
      this.hideCsv()
      this.hideSia()
      this.hideMyAlarmSia()
      this.hideMvr()
      // HARD LOOKUP TO B2B_REPORTING_TYPES
      if (template.type == 'cid') {
        this.setupContactIdTemplateForProfile(template)
        this.profileTemplateTypeFormInputTarget.value = template.type
        this.accountNumberHolderTarget.classList.remove('d-none')
        if (this.hasLanguageValue && this.languageValue == 'el') {
          this.showGreekContactIDWarning
        }
      } else if (template.type == 'csv') {
        this.setupCsvTemplateForProfile(template)
        this.profileTemplateTypeFormInputTarget.value = template.type
        this.accountNumberHolderTarget.classList.remove('d-none')
      } else if (template.type == 'sia') {
        this.setupSiaTemplateForProfile(template)
        this.profileTemplateTypeFormInputTarget.value = template.type
        this.accountNumberHolderTarget.classList.remove('d-none')
      } else if (template.type == 'mcdi') {
        this.setupMyAlarmSiaTemplateForProfile(template)
        this.profileTemplateTypeFormInputTarget.value = template.type
        this.accountNumberHolderTarget.classList.remove('d-none')
      } else if (template.type == 'mvr') {
        this.setupMvrTemplateForProfile(template)
        this.profileTemplateTypeFormInputTarget.value = template.type
        this.accountNumberHolderTarget.classList.remove('d-none')
      } else {
        this.hideContactId()
        this.hideCsv()
        this.hideSia()
        this.hideMyAlarmSia()
        this.hideMvr()
        this.profileTemplateTypeFormInputTarget.value = ''
        this.accountNumberTarget.value = ''
        this.accountNumberHolderTarget.classList.add('d-none')
        this.selectedTemplate = null
      }
    } else {
        this.hideContactId()
        this.hideCsv()
        this.hideSia()
        this.hideMyAlarmSia()
        this.hideMvr()
        this.profileTemplateTypeTarget.value = -1 
        this.profileTemplateTypeFormInputTarget.value = ''
        this.accountNumberTarget.value = ''
        this.accountNumberHolderTarget.classList.add('d-none')
        this.selectedTemplate = null
    }
  }

  deleteReportingTemplateProfile(event) {
    if (this.hasDeleteReportingTemplateProfileButtonTarget) {
      this.deleteReportingTemplateProfileButtonTarget.href = event.params['deleteRoute']
      if (!this.deleteReportingTemplateModal) {
        this.deleteReportingTemplateModal = new bootstrap.Modal(this.deleteReportingTemplateModalTarget, { keyboard: false });
      }
      if (this.deleteReportingTemplateModal) {
        this.deleteReportingTemplateModal.show()
      }
    }
  }

  setupContactIdTemplateForProfile(template) {
    this.profileTemplateTypeTarget.value = template.type
    this.contactIdOptionsTarget.classList.remove('d-none')
    this.phoneTarget.value = template.cid_primary_destination
    this.contactIdPrimaryPhoneTarget.value = template.cid_primary_destination
    if (template.cid_primary_destination.startsWith('+6113'))  {
      this.contactIdSecondaryPhoneHolderTarget.classList.add('d-none')
    } else {
      this.contactIdSecondaryPhoneHolderTarget.classList.remove('d-none')
      this.secondaryPhoneTarget.value = template.cid_secondary_destination
      this.contactIdSecondaryPhoneTarget.value = template.cid_secondary_destination
    }
    this.phoneTarget.dispatchEvent(new Event('change'))
  }

  setupCsvTemplateForProfile(template) {
    this.profileTemplateTypeTarget.value = template.type
    this.csvOptionsTarget.classList.remove('d-none')
    this.csvPrimaryIpAddressTarget.value = template.csv_primary_ip_address
    this.csvPrimaryIpPortTarget.value = template.csv_primary_ip_port
    this.csvSecondaryIpAddressTarget.value = template.csv_secondary_ip_address
    this.csvSecondaryIpPortTarget.value = template.csv_secondary_ip_port
    this.csvProtocolTarget.value = template.csv_protocol
    if (this.hasCsvProtocolChoiceTarget) {
      this.csvProtocolChoiceTarget.value = template.csv_protocol;
      this.csvProtocolChoiceTarget.dispatchEvent(new Event('change'));
    }
    this.csvEncryptionTarget.value = template.csv_encryption
    this.csvUsernameTarget.value = template.csv_username
    this.csvPasswordTarget.value = template.csv_password
    this.csvProtocolTarget.dispatchEvent(new Event('change'));
  }

  setupSiaTemplateForProfile(template) {
    this.profileTemplateTypeTarget.value = template.type
    this.siaOptionsTarget.classList.remove('d-none')
    this.siaPrimaryIpAddressTarget.value = template.sia_primary_ip_address
    this.siaPrimaryIpPortTarget.value = template.sia_primary_ip_port
    this.siaSecondaryIpAddressTarget.value = template.sia_secondary_ip_address
    this.siaSecondaryIpPortTarget.value = template.sia_secondary_ip_port
    this.siaProtocolTarget.value = template.sia_protocol
    if (this.hasSiaProtocolChoiceTarget) {
      this.siaProtocolChoiceTarget.value = template.sia_protocol
      this.siaProtocolChoiceTarget.dispatchEvent(new Event('change'))
    }
    this.siaEncryptionTarget.value = template.sia_encryption
    this.siaMessageFormatTarget.value = template.sia_message_format
    this.siaReceiverNumberTarget.value = template.sia_receiver_number
    this.siaAccountPrefixTarget.value = template.sia_account_prefix
    this.siaAddEventTextHolderTarget.classList.remove('d-none')
    this.siaAddEventTextTarget.value = template.sia_add_event_text
    this.siaAddEventTextTarget.checked = template.sia_add_event_text
    this.siaAddTimestampHolderTarget.classList.remove('d-none')
    this.siaAddTimestampTarget.value = template.sia_add_timestamp
    this.siaAddTimestampTarget.checked = template.sia_add_timestamp
    this.siaProtocolTarget.dispatchEvent(new Event('change'))
    this.siaMessageFormatTarget.dispatchEvent(new Event('change'))
  }

  setupMyAlarmSiaTemplateForProfile(template) {
    this.profileTemplateTypeTarget.value = template.type
    this.myAlarmSiaOptionsTarget.classList.remove('d-none')
    this.myAlarmSiaBaseStationIdTarget.value = template.sia_base_stations_id
    this.myAlarmSiaMessageFormatTarget.value = template.myalarm_sia_message_format
    this.myAlarmSiaReceiverNumberTarget.value = template.myalarm_sia_receiver_number
    this.myAlarmSiaAccountPrefixTarget.value = template.myalarm_sia_account_prefix
    this.myAlarmSiaAddEventTextTarget.value = template.myalarm_sia_add_event_text
  }

  setupMvrTemplateForProfile(template) {
    this.profileTemplateTypeTarget.value = template.type;
    this.mvrOptionsTarget.classList.remove('d-none');
    this.mvrBaseStationIdTarget.value = template.mvr_base_stations_id;
    this.mvrReceiverNumberTarget.value = template.mvr_receiver_number;
    this.mvrLineNumberTarget.value = template.mvr_line_number;
  }

  checkValidAccountNumber(event) {
    var regexResult = false
    if (this.selectedTemplate) {
      if (this.selectedTemplate.type == 'cid' || this.selectedTemplate.type == 'csv') {
        regexResult = /^([0-9B-Fb-f]{4})$/.test(this.accountNumberTarget.value)
      } else {
        regexResult = /^([0-9B-Fb-f]{3,16})$/.test(this.accountNumberTarget.value) // SIA
      }
    }
    if (regexResult) {
      if (this.checkIfAccountNumberInUse()) {
        return
      }
      this.accountNumberTarget.classList.remove('alert-danger')
      this.accountNumberTarget.classList.remove('bg-unverified')
      this.accountNumberTarget.classList.add('alert-success')
      this.accountNumberTarget.classList.add('bg-verified')
    } else {
      this.accountNumberTarget.classList.remove('alert-success')
      this.accountNumberTarget.classList.remove('bg-verified')
      this.accountNumberTarget.classList.add('alert-danger')
      this.accountNumberTarget.classList.add('bg-unverified')
    }
  }

  resetContactId() {
    if (this.hasContactIdPrimaryPhoneTarget) {
      this.contactIdPrimaryPhoneTarget.value = ''
      this.contactIdSecondaryPhoneTarget.value = ''
      this.contactIdPrimaryPhoneTarget.removeAttribute('required')
      this.contactIdSecondaryPhoneTarget.removeAttribute('required')
      this.phoneTarget.value = ''
      this.secondaryPhoneTarget.value = ''
      this.phoneTarget.removeAttribute('required')
      this.secondaryPhoneTarget.removeAttribute('required')
    }
  }

  resetCsv() {
    this.csvPrimaryIpAddressTarget.value = ''
    this.csvPrimaryIpPortTarget.value = ''
    this.csvSecondaryIpAddressTarget.value = ''
    this.csvSecondaryIpPortTarget.value = ''
    this.csvProtocolTarget.value = ''
    if (this.hasCsvProtocolChoiceTarget) {
      this.csvProtocolChoiceTarget.value = ''
      this.csvProtocolChoiceTarget.dispatchEvent(new Event('change'));
    }
    this.csvEncryptionTarget.value = ''
    this.csvUsernameTarget.value = ''
    this.csvPasswordTarget.value = ''
    this.csvPrimaryIpAddressTarget.removeAttribute('required')
    this.csvPrimaryIpPortTarget.removeAttribute('required')
    this.csvProtocolTarget.removeAttribute('required')
    this.csvProtocolTarget.dispatchEvent(new Event('change'));
  }

  resetSia() {
    this.siaPrimaryIpAddressTarget.value = ''
    this.siaPrimaryIpPortTarget.value = ''
    this.siaSecondaryIpAddressTarget.value = ''
    this.siaSecondaryIpPortTarget.value = ''
    this.siaProtocolTarget.value = ''
    this.siaEncryptionTarget.value = ''
    this.siaMessageFormatTarget.value = ''
    this.siaReceiverNumberTarget.value = ''
    this.siaAccountPrefixTarget.value = ''
    this.siaAddEventTextTarget.value = ''
    this.siaAddTimestampTarget.value = ''
    this.siaAddEventTextHolderTarget.classList.add('d-none')
    this.siaAddEventTextTarget.value = ''
    this.siaAddEventTextTarget.checked = ''
    this.siaAddEventTextTarget.disabled = true
    this.siaAddTimestampHolderTarget.classList.add('d-none')
    this.siaAddTimestampTarget.value = ''
    this.siaAddTimestampTarget.checked = ''
    this.siaAddTimestampTarget.disabled = true
    if (this.hasSiaProtocolChoiceTarget) {
      this.siaProtocolChoiceTarget.value = ''
      this.siaProtocolChoiceTarget.dispatchEvent(new Event('change'))
    }
    this.siaPrimaryIpAddressTarget.removeAttribute('required')
    this.siaPrimaryIpPortTarget.removeAttribute('required')
    this.siaProtocolTarget.removeAttribute('required')
    this.siaProtocolTarget.dispatchEvent(new Event('change'))
    this.siaMessageFormatTarget.removeAttribute('required')
    this.siaMessageFormatTarget.dispatchEvent(new Event('change'))
  }

  resetMyAlarmSia() {
    this.myAlarmSiaBaseStationIdTarget.value = ''
    if (this.hasMyAlarmSiaIdentificationTarget) {
      this.myAlarmSiaIdentificationTarget.value = ''
      this.myAlarmSiaIdentificationTarget.removeAttribute('required')
    }
    this.myAlarmSiaAccountPrefixTarget.value = ''
    this.myAlarmSiaMessageFormatTarget.value = ''
    this.myAlarmSiaReceiverNumberTarget.value = ''
    this.myAlarmSiaBaseStationIdTarget.removeAttribute('required')
    this.myAlarmSiaMessageFormatTarget.removeAttribute('required')
    this.myAlarmSiaBaseStationIdTarget.dispatchEvent(new Event('change'))
    this.myAlarmSiaMessageFormatTarget.dispatchEvent(new Event('change'))
    this.myAlarmSiaAddEventTextHolderTarget.classList.add('d-none')
    this.myAlarmSiaAddEventTextTarget.value = ''
    this.myAlarmSiaAddEventTextTarget.checked = ''
    this.myAlarmSiaAddEventTextTarget.disabled = true
  }

  resetMvr() {
    this.mvrBaseStationIdTarget.value = ''
    if (this.hasMvrIdentificationTarget) {
      this.mvrIdentificationTarget.value = ''
      this.mvrIdentificationTarget.removeAttribute('required')
    }
    this.mvrReceiverNumberTarget.value = ''
    this.mvrLineNumberTarget.value = ''
    this.mvrBaseStationIdTarget.removeAttribute('required')
    this.mvrBaseStationIdTarget.dispatchEvent(new Event('change'))
  }

  showContactId() {
    if (this.hasContactIdOptionsTarget) {
      this.contactIdOptionsTarget.classList.remove('d-none')
    }
    if (this.hasContactIdTypeChoiceTarget) {
      this.contactIdTypeChoiceTarget.classList.remove('d-none')
    }
  }

  hideContactId() {
    if (this.hasContactIdOptionsTarget) {
      this.contactIdOptionsTarget.classList.add('d-none')
      if (this.hasContactIdTypeChoiceTarget) {
        this.contactIdTypeChoiceTarget.classList.add('d-none')
      }
      this.contactIdPrimaryPhoneTarget.classList.add('d-none')
      this.contactIdSecondaryPhoneTarget.classList.add('d-none')
      this.resetContactId()
    }
  }

  show13numberOptions() {
    this.contactIdPrimaryPhoneTarget.classList.remove('d-none')
    this.contactIdSecondaryPhoneTarget.classList.add('d-none')
    this.phoneTarget.setAttribute('required', 'true')
    this.secondaryPhoneTarget.removeAttribute('required')
    this.contactIdPrimaryDestinationTarget.setAttribute('required', 'true')
    this.contactIdSecondaryDestinationTarget.removeAttribute('required')
  }

  showLandlineOptions() {
    this.contactIdPrimaryPhoneTarget.classList.remove('d-none')
    this.contactIdSecondaryPhoneTarget.classList.remove('d-none')
    this.phoneTarget.setAttribute('required', 'true')
    this.secondaryPhoneTarget.setAttribute('required', 'true')
    this.contactIdPrimaryDestinationTarget.setAttribute('required', 'true')
    this.contactIdSecondaryDestinationTarget.setAttribute('required', 'true')
  }

  showCsv() {
    if (this.hasCsvOptionsTarget) {
      this.csvOptionsTarget.classList.remove('d-none')
      this.csvPrimaryIpAddressTarget.required = true
      this.csvPrimaryIpPortTarget.required = true
      this.csvProtocolTarget.required = true
    }
  }

  hideCsv() {
    if (this.hasCsvOptionsTarget) {
      this.csvOptionsTarget.classList.add('d-none')
      this.resetCsv()
    }
  }

  showSia() {
    if (this.hasSiaOptionsTarget) {
      this.siaOptionsTarget.classList.remove('d-none')
      this.siaPrimaryIpAddressTarget.required = true
      this.siaPrimaryIpPortTarget.required = true
      this.siaProtocolTarget.required = true
      this.siaMessageFormatTarget.required = true
    }
  }

  updateSiaMessageFormatOptions() {
    if (this.siaMessageFormatTarget.value == 'SIA-DCS') {
      this.siaAddEventTextHolderTarget.classList.remove('d-none')
      this.siaAddEventTextTarget.disabled = false
      this.siaAddTimestampHolderTarget.classList.remove('d-none')
      this.siaAddTimestampTarget.disabled = false
    } else {
      this.siaAddEventTextHolderTarget.classList.add('d-none')
      this.siaAddEventTextTarget.value = ''
      this.siaAddEventTextTarget.checked = ''
      this.siaAddEventTextTarget.disabled = true
      this.siaAddTimestampHolderTarget.classList.add('d-none')
      this.siaAddTimestampTarget.value = ''
      this.siaAddTimestampTarget.checked = ''
      this.siaAddTimestampTarget.disabled = true
    }
  }

  updateMyAlarmSiaMessageFormatOptions() {
    if (this.myAlarmSiaMessageFormatTarget.value == 'SIA-DCS') {
      this.myAlarmSiaAddEventTextHolderTarget.classList.remove('d-none')
      this.myAlarmSiaAddEventTextTarget.disabled = false
    } else {
      this.myAlarmSiaAddEventTextHolderTarget.classList.add('d-none')
      this.myAlarmSiaAddEventTextTarget.value = ''
      this.myAlarmSiaAddEventTextTarget.checked = ''
      this.myAlarmSiaAddEventTextTarget.disabled = true
    }
  }

  hideSia() {
    if (this.hasSiaOptionsTarget) {
      this.siaOptionsTarget.classList.add('d-none')
      this.resetSia()
    }
  }

  showMyAlarmSia() {
    if (this.hasMyAlarmSiaOptionsTarget) {
      if (this.hasMyAlarmSiaIdentificationTarget) {
        this.myAlarmSiaIdentificationTarget.required = true
      }
      this.myAlarmSiaOptionsTarget.classList.remove('d-none')
      this.myAlarmSiaBaseStationIdTarget.required = true
      this.myAlarmSiaMessageFormatTarget.required = true
    }
  }

  hideMyAlarmSia() {
    if (this.hasMyAlarmSiaOptionsTarget) {
      this.myAlarmSiaOptionsTarget.classList.add('d-none')
      this.resetMyAlarmSia()
    }
  }

  showMvr() {
    if (this.hasMvrOptionsTarget) {
      if (this.hasMvrIdentificationTarget) {
        this.mvrIdentificationTarget.required = true
      }
      this.mvrOptionsTarget.classList.remove('d-none')
      this.mvrBaseStationIdTarget.required = true
    }
  }

  hideMvr() {
    if (this.hasMvrOptionsTarget) {
      this.mvrOptionsTarget.classList.add('d-none')
      this.resetMvr()
    }
  }

  findMyAlarmSiaBaseStationFromIdentification(event) {
    var identificationCode = event.target.value;
    if (identificationCode.length >= 6) {
      var data = JSON.stringify({ identification_code: identificationCode, authenticity_token: document.querySelectorAll('meta[name="csrf-token"]')[0]['content'] })
      fetch('/checkSiaBaseStation', { method: 'post', headers: { 'Content-Type': 'application/json;charset=utf-8'}, body: data})
      .then(response => response.json())
      .then(responseData => {
        if (Object.keys(responseData).length !== 0) {
          this.myAlarmSiaBaseStationIdHolderTarget.classList.remove('d-none')
          while (this.myAlarmSiaBaseStationIdTarget.firstChild) {
            this.myAlarmSiaBaseStationIdTarget.removeChild(this.myAlarmSiaBaseStationIdTarget.firstChild)
          }
          var selectedOption = document.createElement('option')
          selectedOption.selected = true
          selectedOption.innerHTML = responseData['name']
          this.myAlarmSiaBaseStationIdTarget.appendChild(selectedOption)
        }
      })
      .catch(err => {
        this.myAlarmSiaBaseStationIdHolderTarget.classList.add('d-none')
        while (this.myAlarmSiaBaseStationIdTarget.firstChild) {
          this.myAlarmSiaBaseStationIdTarget.removeChild(this.myAlarmSiaBaseStationIdTarget.firstChild)
        }
      })
    } else {
      this.myAlarmSiaBaseStationIdHolderTarget.classList.add('d-none')
      while (this.myAlarmSiaBaseStationIdTarget.firstChild) {
        this.myAlarmSiaBaseStationIdTarget.removeChild(this.myAlarmSiaBaseStationIdTarget.firstChild)
      }
    }
  }

  findMvrBaseStationFromIdentification(event) {
    var identificationCode = event.target.value;
    if (identificationCode.length >= 6) {
      var data = JSON.stringify({ identification_code: identificationCode, authenticity_token: document.querySelectorAll('meta[name="csrf-token"]')[0]['content'] })
      fetch('/checkMvrBaseStation', { method: 'post', headers: { 'Content-Type': 'application/json;charset=utf-8'}, body: data})
      .then(response => response.json())
      .then(responseData => {
        if (Object.keys(responseData).length !== 0) {
          this.mvrBaseStationIdHolderTarget.classList.remove('d-none')
          while (this.mvrBaseStationIdTarget.firstChild) {
            this.mvrBaseStationIdTarget.removeChild(this.mvrBaseStationIdTarget.firstChild);
          }
          var selectedOption = document.createElement('option');
          selectedOption.selected = true;
          selectedOption.innerHTML = responseData['name'];
          this.mvrBaseStationIdTarget.appendChild(selectedOption);
          if (responseData['message_format'] == 'mlr2') { // B2B_REPORTING_MLR_FORMATS
            this.mvrLineNumberTarget.maxLength = 1;
          } else {
            this.mvrLineNumberTarget.maxLength = 3;
          }
        }
      })
      .catch(err => {
        console.log(err);
        this.mvrBaseStationIdHolderTarget.classList.add('d-none')
        while (this.mvrBaseStationIdTarget.firstChild) {
          this.mvrBaseStationIdTarget.removeChild(this.mvrBaseStationIdTarget.firstChild)
        }
      })
    } else {
      this.mvrBaseStationIdHolderTarget.classList.add('d-none')
      while (this.mvrBaseStationIdTarget.firstChild) {
        this.mvrBaseStationIdTarget.removeChild(this.mvrBaseStationIdTarget.firstChild)
      }
    }
  }

  getSelectedTemplateType() {
    if (this.hasTemplateTypeTarget) {
      return String([...this.templateTypeTarget.selectedOptions].map(option => option.value)[0])
    }
  }
}
