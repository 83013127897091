import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import utilsScript from 'intl-tel-input/build/js/utils'

// Connects to data-controller="phone"
export default class extends Controller {
  static values = {
    clientCountry: String,
    webServType: String,
    readOnly: Boolean,
    contactIdTranslations: Array,
  }
  static targets = [
    "phone", "secondaryPhone", "mobilePhone", "submitForm",
    "editPage", "ruleType",
    "primaryDestination", "secondaryDestination", "contactIdReportingPage",
    "submitUpdateTemplateForm",
    "accountRegistrationPage", "accountProfilePage",
    "newCodeModal"
  ]
  allowedCountries = [
    "at", "au", "nz", "it", "gr", "es", "de", "nl", "se", "mt", "ch", "be",
    "hk", "pe", "tw", "ca", "ie", "pl", "th", "cn", "il", "pt", "tr", "cy",
    "sg", "gb", "dk", "my", "kr", "us", "fr", "mx", "za"
  ]
  preferredCountries = [
    "au", "nz", "it", "gr"
  ]

  accountRegistrationPageTargetConnected(target) {
    this.initMobilePhone()
  }

  accountProfilePageTargetConnected(target) {
    this.initMobilePhone()
  }

  newCodeModalTargetConnected() {
    this.initMobilePhone()
  }

  setupAppCodeSmsModal(event) {
    this.mobilePhoneTargetInput = intlTelInput(this.mobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.allowedCountries,
      preferredCountries: this.preferredCountries,
      customContainer: 'w-100'
    })
    this.appCodeSmsSubmit = document.getElementById(event.params['modal']).querySelector('input[type="submit"]')
  }

  editPageTargetConnected(target) {
    if (this.hasRuleTypeTarget) {
      if (this.ruleTypeTarget.value == 2) {
        this.initMobilePhone()
      } else if (this.ruleTypeTarget.value == 3 || this.ruleTypeTarget.value == 4) {
        this.initPhone()
      }
    }
  }

  contactIdReportingPageTargetConnected(target) {
    this.initContactIdPhones()
  }

  initPhone() {
    if (this.hasPhoneTarget) {
      this.phoneTargetInput = intlTelInput(this.phoneTarget, {
        initialCountry: this.clientCountryValue,
        onlyCountries: this.allowedCountries,
        preferredCountries: this.preferredCountries,
        customContainer: 'w-100'
      })
    }
  }

  validateLandlineAndMobile(event) {
    if (this.hasPhoneTarget) {
      var validNumberType = this.phoneTargetInput.getNumberType() == intlTelInputUtils.numberType.MOBILE ||
                            this.phoneTargetInput.getNumberType() == intlTelInputUtils.numberType.FIXED_LINE ||
                            this.phoneTargetInput.getNumberType() == intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE
      if (this.phoneTargetInput.isValidNumber() && validNumberType) {
        this.phoneTarget.classList.remove('bg-unverified')
        this.phoneTarget.classList.add('bg-verified')
        this.submitFormTarget.disabled = false
        if (document.getElementById(event.params['inputTarget'])) {
          document.getElementById(event.params['inputTarget']).value = this.phoneTargetInput.getNumber()
        }
      } else {
        this.phoneTarget.classList.remove('bg-verified')
        this.phoneTarget.classList.add('bg-unverified')
        this.submitFormTarget.disabled = true
        if (document.getElementById(event.params['inputTarget'])) {
          document.getElementById(event.params['inputTarget']).value = ''
        }
      }
    }
  }

  destroyPhone() {
    if (this.phoneTargetInput) {
      this.phoneTargetInput.destroy()
      this.phoneTargetInput = null
    }
  }

  initMobilePhone() {
    if (this.hasMobilePhoneTarget) {
      this.mobilePhoneTargetInput = intlTelInput(this.mobilePhoneTarget, {
        initialCountry: this.clientCountryValue,
        onlyCountries: this.allowedCountries,
        preferredCountries: this.preferredCountries,
        customContainer: 'w-100'
      })
    }
  }

  validateMobilePhone(event) {
    if (this.hasMobilePhoneTarget) {
      var validNumberType = this.mobilePhoneTargetInput.getNumberType() == intlTelInputUtils.numberType.MOBILE ||
                          this.mobilePhoneTargetInput.getNumberType() == intlTelInputUtils.numberType.FIXED_LINE
      if (this.mobilePhoneTargetInput.getSelectedCountryData() && this.mobilePhoneTargetInput.getSelectedCountryData()['iso2'] == 'us') {
        // Note that in the US there's no way to differentiate between fixed-line and mobile numbers, so instead it will return FIXED_LINE_OR_MOBILE.
        // https://github.com/jackocnr/intl-tel-input
        validNumberType = this.mobilePhoneTargetInput.getNumberType() == intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE
      }
      if (this.mobilePhoneTargetInput.isValidNumber() && validNumberType) {
        this.mobilePhoneTarget.classList.remove('bg-unverified')
        this.mobilePhoneTarget.classList.add('bg-verified')
        this.submitFormTarget.disabled = false
        if (document.getElementById(event.params['inputTarget'])) {
          document.getElementById(event.params['inputTarget']).value = this.mobilePhoneTargetInput.getNumber()
        }
      } else {
        this.mobilePhoneTarget.classList.remove('bg-verified')
        this.mobilePhoneTarget.classList.add('bg-unverified')
        this.submitFormTarget.disabled = true
        if (document.getElementById(event.params['inputTarget'])) {
          document.getElementById(event.params['inputTarget']).value = ''
        }
      }
    }
  }

  validateAppCodeSmsMobilePhone(event) {
    var validNumberType = this.mobilePhoneTargetInput.getNumberType() == intlTelInputUtils.numberType.MOBILE ||
                        this.mobilePhoneTargetInput.getNumberType() == intlTelInputUtils.numberType.FIXED_LINE
    if (this.mobilePhoneTargetInput.getSelectedCountryData() && this.mobilePhoneTargetInput.getSelectedCountryData()['iso2'] == 'us') {
      // Note that in the US there's no way to differentiate between fixed-line and mobile numbers, so instead it will return FIXED_LINE_OR_MOBILE.
      // https://github.com/jackocnr/intl-tel-input
      validNumberType = this.mobilePhoneTargetInput.getNumberType() == intlTelInputUtils.numberType.FIXED_LINE_OR_MOBILE
    }
    if (this.mobilePhoneTargetInput.isValidNumber() && validNumberType) {
      event.target.classList.remove('bg-unverified')
      event.target.classList.add('bg-verified')
      this.appCodeSmsSubmit.disabled = false
      if (document.getElementById(event.params['inputTarget'])) {
        document.getElementById(event.params['inputTarget']).value = this.mobilePhoneTargetInput.getNumber()
      }
    } else {
      event.target.classList.remove('bg-verified')
      event.target.classList.add('bg-unverified')
      this.appCodeSmsSubmit.disabled = true
      if (document.getElementById(event.params['inputTarget'])) {
        document.getElementById(event.params['inputTarget']).value = ''
      }
    }
  }

  destroyMobilePhone() {
    if (this.mobilePhoneTargetInput) {
      this.mobilePhoneTargetInput.destroy()
      this.mobilePhoneTargetInput = null
    }
  }

  initContactIdPhones() {
    var country = 'auto'
    if (this.hasPrimaryDestinationTarget) {
      country = this.phoneDestinationCountry(this.hasPrimaryDestinationTarget.value)
    }
    this.primaryContactIdPhone = intlTelInput(this.phoneTarget, {
      initialCountry: country,
      onlyCountries: ['au', 'nz', 'it', 'gr'],
      placeholderNumberType: 'FIXED_LINE',
      allowDropdown: !this.readOnlyValue,
      customContainer: 'w-100'
    })
    var country = 'auto'
    if (this.hasSecondaryDestinationTarget) {
      country = this.phoneDestinationCountry(this.hasSecondaryDestinationTarget.value)
    }
    this.secondaryContactIdPhone = intlTelInput(this.secondaryPhoneTarget, {
      initialCountry: country,
      onlyCountries: ['au', 'nz', 'it', 'gr'],
      placeholderNumberType: 'FIXED_LINE',
      allowDropdown: !this.readOnlyValue,
      customContainer: 'w-100'
    })
    //this.validateContactIdPhoneNumber(this.phoneTarget, this.primaryDestinationTarget, this.primaryDestinationTarget.value);
    //this.validateContactIdPhoneNumber(this.secondaryPhoneTarget, this.secondaryDestinationTarget, this.secondaryDestinationTarget.value);
    //this.refreshFormSubmitAvailability();
  }

  phoneDestinationCountry(destinationValue) {
    if (destinationValue == undefined) {
      return 'auto'
    }
    if (this.hasWebServTypeValue) {
      if (this.webServTypeValue == 'europe_production') {
        if (destinationValue.startsWith('+39')) {
          return 'it'
        } else if (destinationValue.startsWith('+31')) {
          return 'gr'
        }
      } else {
        if (destinationValue.startsWith('+61')) {
          return 'au'
        } else if (destinationValue.startsWith('+64')) {
          return 'nz'
        }
      }
    }
    return 'auto'
  }

  allowedContactIdCountries() {
    if (this.hasWebServTypeValue) {
      if (this.webServTypeValue == 'europe_production') {
        return ['it', 'gr']
      } else {
        return ['au', 'nz']
      }
    }
  }

  refreshFormSubmitAvailability() {
    if (this.phoneTarget.classList.contains('bg-verified') &&
      this.secondaryPhoneTarget.classList.contains('bg-verified')) {
      if (this.hasSubmitUpdateTemplateFormTarget) {
        this.submitUpdateTemplateFormTarget.disabled = false;
      }
      this.submitFormTarget.disabled = false;
    } else {
      if (this.hasSubmitUpdateTemplateFormTarget) {
        this.submitUpdateTemplateFormTarget.disabled = true;
      }
      this.submitFormTarget.disabled = true;
    }
  }

  validateOutgoingContactIdPhoneNumber(event) {
    var numberEntry = event.target.value
    var formTarget;
    if (event.target.dataset['phoneTarget'] == 'phone') {
      numberEntry = this.primaryContactIdPhone.getNumber()
      formTarget = this.primaryDestinationTarget;
    } else if (event.target.dataset['phoneTarget'] == 'secondaryPhone') {
      numberEntry = this.secondaryContactIdPhone.getNumber()
      formTarget = this.secondaryDestinationTarget;
    } else {
      return false
    }
    var result = this.validateContactIdPhoneNumber(event.target, formTarget, numberEntry);
    this.refreshFormSubmitAvailability();
    return result;
  }

  validateContactIdPhoneNumber(visualTarget, formEntryTarget, numberEntry) {
    const auAreaCodes = ['2', '3', '7', '8']
    const nzAreaCodes = ['3', '4', '6', '7', '9']
    const elAreaCodes = ['21', '22', '23', '24', '25', '26', '27', '28']
    var i = 0;
    var len = numberEntry.length;
    var countryCode = '';
    var areaCode = '';
    var phoneNumber = '';
    var result = false;
    while (i < len) {
      if (i < 3 && countryCode.length < 2 && !(i == 0 && numberEntry[i] == '+')) {
        countryCode = countryCode + numberEntry[i];
      } else if (countryCode == '61') {
        if (areaCode.length == 0) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (phoneNumber.length == 8 && $.inArray(areaCode, auAreaCodes) > -1) {
            if (!(phoneNumber[0] == '1' && phoneNumber[1] == '3')) {
              result = true
            }
          } else if (phoneNumber.length == 9 && numberEntry[3] == '1' && numberEntry[4] == '3') {
            result = (this.contactIdTranslationsValue.indexOf(numberEntry) > -1)
          } else if (phoneNumber.length == 7 && numberEntry[3] == '1' && numberEntry[4] == '3' && numberEntry[5] == '4' && numberEntry[6] == '5') {
            result = (this.contactIdTranslationsValue.indexOf(numberEntry) > -1)
          }
        }
      } else if (countryCode == '64') {
        if (areaCode.length == 0) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (phoneNumber.length == 7 && $.inArray(areaCode, nzAreaCodes) > -1) {
            result = true
          }
        }
      } else if (countryCode == '39') {
        if (areaCode.length < 2) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (areaCode.length == 2 && phoneNumber.length >= 5 && phoneNumber.length <= 10) {
            result = true;
          }
        }
      } else if (countryCode == '30') {
        if (areaCode.length < 2) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (areaCode.length == 2 && phoneNumber.length == 8 && $.inArray(areaCode, elAreaCodes) > -1) {
            result = true;
          }
        }
      }
      i++;
    }
    if (result) {
      visualTarget.classList.remove('bg-unverified');
      visualTarget.classList.add('bg-verified');
      formEntryTarget.value = numberEntry;
    } else {
      visualTarget.classList.remove('bg-verified');
      visualTarget.classList.add('bg-unverified');
      formEntryTarget.value = '';
    }
    return result;
  }
}
