import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="installer-users--account-recovery"
export default class extends Controller {
  static targets = [
    "header", "mobileForm", "emailForm",
    "mobilePhone", "email", "formCaptchaAndSubmit",
    "customerNumberInput", "emailInput", "mobilePhoneInput", "recoverUsernameButton",
    "submitForm"
  ]

  showAccountRecoveryWithEmail() {
    this.emailFormTarget.classList.remove('d-none')
    this.mobileFormTarget.classList.add('d-none')
  }

  showAccountRecoveryWithMobile() {
    this.emailFormTarget.classList.add('d-none')
    this.mobileFormTarget.classList.remove('d-none')
  }
}
