import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import 'chartjs-adapter-luxon'
import zoomPlugin from 'chartjs-plugin-zoom'
Chart.register(zoomPlugin);

// Connects to data-controller="admin--packet-graphing"
export default class extends Controller {
  static values = {
    serverName: String,
    graphDuration: Number,
  }
  static targets = [
    "pollsGraph", "packetsGraph", "cpuGraph", "delayGraph", "udpGraph",
    "udpErrorsGraph", "panelsOnlineGraph", "queueGraph", "memoryPhysUsageGraph",
    "memoryUsageGraph", "baseStationReportingGraph", "timeUpdatesGraph",
    "durationChoice", "serverChoice"
  ]

  showGraphsForServer(event) {
    this.serverNameValue = event.params['serverName'];
    if (this.hasServerChoiceTarget) {
      this.serverChoiceTarget.querySelectorAll('.btn.active').forEach(element => element.classList.remove('active'))
    }
    event.target.classList.add('active')
    this.buildAllGraphs();
  }

  rebuildGraphsWithNewDuration(event) {
    this.graphDurationValue = event.params['graphDuration']
    if (this.hasDurationChoiceTarget) {
      this.durationChoiceTarget.querySelectorAll('.btn-outline-primary.active').forEach(element => element.classList.remove('active'))
    }
    event.target.classList.add('active')
    this.buildAllGraphs();
  }

  buildAllGraphs() {
    fetch('packetStatisticsGraphData/' + this.serverNameValue + '/' + this.graphDurationValue,
          { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
      .then(response => response.json())
      .then(responseData => {
        if (this.charts) {
          this.charts.forEach(chart => {
            this.refreshChart(chart, responseData);
          })
        } else {
          this.charts = [];
          this.charts.push(this.buildPollsGraph(responseData));
          this.charts.push(this.buildPacketsGraph(responseData));
          this.charts.push(this.buildCpuGraph(responseData));
          this.charts.push(this.buildDelayGraph(responseData));
          this.charts.push(this.buildUdpGraph(responseData));
          this.charts.push(this.buildUdpErrorsGraph(responseData));
          this.charts.push(this.buildPanelsOnlineGraph(responseData));
          this.charts.push(this.buildQueueGraph(responseData));
          this.charts.push(this.buildMemoryPhysUsageGraph(responseData));
          this.charts.push(this.buildMemoryUsageGraph(responseData));
          this.charts.push(this.buildBaseStationReportingGraph(responseData));
          this.charts.push(this.buildTimeUpdatesGraph(responseData));
        }
      })
      .catch(err => {})
  }

  buildPollsGraph(data) {
    return this.buildGraph(
      this.pollsGraphTarget,
      data,
      [
        'Poll Packets IN', 'Poll Packets OUT', 'Skinny Poll Packets IN'
      ],
      [
        'packets_poll', 'packets_out_poll', 'packets_skinny_poll'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildPacketsGraph(data) {
    return this.buildGraph(
      this.packetsGraphTarget,
      data,
      [
        'Packets In', 'Packets Out', 'Packets Forwarded', 'In Queue Count'
      ],
      [
        'packets_in', 'packets_out', 'packets_forwarded', 'in_queue_count'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildCpuGraph(data) {
    return this.buildGraph(
      this.cpuGraphTarget,
      data,
      [
        'CPU Load 1 Min', 'CPU Load 5 Min', 'CPU Load 15 Min'
      ],
      [
        'cpu_average_1_min', 'cpu_average_5_min', 'cpu_average_15_min'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildDelayGraph(data) {
    return this.buildGraph(
      this.delayGraphTarget,
      data,
      [
        'Packet Out Delay'
      ],
      [
        'packet_out_delay'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildUdpGraph(data) {
    return this.buildGraph(
      this.udpGraphTarget,
      data,
      [
        'UDP In Datagrams', 'UDP Out Datagrams'
      ],
      [
        'udp_in_datagrams', 'udp_out_datagrams'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildUdpErrorsGraph(data) {
    return this.buildGraph(
      this.udpErrorsGraphTarget,
      data,
      [
        'UDP No Port Datagrams', 'UDP In Errors', 'UDP Receive Buffer Errors', 'UDP In Checksum Errors'
      ],
      [
        'udp_no_ports', 'udp_in_errors', 'udp_receive_buffer_errors', 'udp_in_checksum_errors'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildPanelsOnlineGraph(data) {
    return this.buildGraph(
      this.panelsOnlineGraphTarget,
      data,
      [
        'Panels Online', 'Poll Reply Queue Count', 'Panel Cache Count'
      ],
      [
        'panels_online', 'poll_reply_queue_count', 'panel_cache_count'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildQueueGraph(data) {
    return this.buildGraph(
      this.queueGraphTarget,
      data,
      [
        'Reply Queue Count', 'App Sessions Active', 'SLK Sessions Active'
      ],
      [
        'reply_queue_count', 'apps_sessions_active', 'solutionlink_sessions_active'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildMemoryPhysUsageGraph(data) {
    return this.buildGraph(
      this.memoryPhysUsageGraphTarget,
      data,
      [
        'Memory Physical KiB'
      ],
      [
        'memory_physical_kib'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildMemoryUsageGraph(data) {
    return this.buildGraph(
      this.memoryUsageGraphTarget,
      data,
      [
        'Memory Pages'
      ],
      [
        'memory_pages'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildBaseStationReportingGraph(data) {
    return this.buildGraph(
      this.baseStationReportingGraphTarget,
      data,
      [
        'CID Sent',
        'CSV Sent (TCP)',
        'CSV Sent (UDP)',
        'CSV Failed',
        'SIA Sent (TCP)',
        'SIA Sent (UDP)',
        'SIA Retries',
        'SIA Failed',
        'MVR Sent',
        'MVR Retries to Relay',
        'MVR Retries',
        'MVR Failed',
      ],
      [
        'cid_queued',
        'csv_reports_sent_tcp',
        'csv_reports_sent_udp',
        'csv_reports_failed',
        'sia_reports_sent_tcp',
        'sia_reports_sent_udp',
        'sia_reports_retry',
        'sia_report_failed',
        'mvr_reports_sent',
        'mvr_reports_relay_retry',
        'mvr_reports_retry',
        'mvr_reports_fail',
      ],
      [
        '', '', ''
      ]
    );
  }

  buildTimeUpdatesGraph(data) {
    return this.buildGraph(
      this.timeUpdatesGraphTarget,
      data,
      [
        'Time Updates Scheduled',
        'Time Updates Requested',
        'Time Updates Denied',
        'Time Zone API Calls OK',
        'Time Zone API Calls Fail'
      ],
      [
        'time_update_scheduled',
        'time_update_requested',
        'time_update_denied',
        'timezone_api_calls_ok',
        'timezone_api_calls_fail'
      ],
      [
        '', '', ''
      ]
    );
  }

  refreshChart(chart, data) {
    chart.data.datasets.forEach(dataset => {
      dataset.data = data;
    })
    chart.update();
  }

  buildDatasetsFromData(labels, data, yAxisDataKeys, colours) {
    var datasets = []
    labels.forEach((key, index) => {
      datasets.push({
        label: labels[index],
        data: data,
        parsing: {
          xAxisKey: 'source_time_',
          yAxisKey: yAxisDataKeys[index]
        },
        borderColor: colours[index],
        backgroundColor: colours[index],
        radius: 0,
        borderWidth: 1,
      })
    })
    return datasets
  }

  buildGraph(target, data, labels, yAxisDataKeys, colours) {
    return new Chart(
      target,
      {
        type: 'line',
        data: {
          labels: data.map(row => new Date(row.source_time_)),
          datasets: this.buildDatasetsFromData(labels, data, yAxisDataKeys, colours)
        },
        options: {
          locale: i18n.locale,
          animation: false,
          parsing: false,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          plugins: {
            decimation: {
              enabled: false,
              algorithm: 'min-max',
            },
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
                modifierKey: 'ctrl',
              },
              zoom: {
                drag: {
                  enabled: true
                },
                mode: 'x',
              },
            }
          },
          scales: {
            x: {
              type: 'time',
              ticks: {
                maxRotation: 0,
                autoSkip: true,
                major: {
                  enabled: true
                },
                font: function(context) {
                  if (context.tick && context.tick.major) {
                    return {
                      weight: 'bold',
                    };
                  }
                }
              }
            }
          }
        }
      }
    )
  }
}
