import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import 'chartjs-adapter-luxon'
import zoomPlugin from 'chartjs-plugin-zoom'
Chart.register(zoomPlugin);

// Connects to data-controller="admin--signal-graphing"
export default class extends Controller {
  static values = {
    siteSerial: Number,
    graphDuration: Number,
    missingPollGap: Number,
    wifiGraphNames: Array,
    cellularGraphNames: Array,
    noWifiSignalData: String,
    noCellularSignalData: String,
    signalStrengthGuidelineLabel: String,
  }
  static targets = [
    "wifiSignalStrengthGraph", "cellularSignalStrengthGraph",
    "wifiSignalStrengthGraphHolder", "cellularSignalStrengthGraphHolder",
    "wifiSignalStrengthGraphResetButton", "cellularSignalStrengthGraphResetButton",
    "durationChoice", "missingPollGapChoice"
  ]

  rebuildGraphsWithNewDuration(event) {
    this.graphDurationValue = event.params['graphDuration']
    this.durationChoiceTarget.querySelectorAll('.btn-outline-primary.active').forEach(element => element.classList.remove('active'))
    event.target.classList.add('active')
    this.graphDurationValue = event.params['graphDuration']
    this.refreshWifiGraph()
    this.refreshCellularGraph()
  }

  rebuildGraphsWithNewMissingPollGap(event) {
    this.missingPollGapChoiceTarget.querySelectorAll('.btn-outline-primary.active').forEach(element => element.classList.remove('active'))
    event.target.classList.add('active')
    this.missingPollGapValue = event.params['missingPollGap']
    this.refreshWifiGraph()
    this.refreshCellularGraph()
  }

  wifiSignalStrengthGraphTargetConnected() {
    this.refreshWifiGraph();
  }

  cellularSignalStrengthGraphTargetConnected() {
    this.refreshCellularGraph();
  }

  resetWifiChartZoom() {
    if (!this.wifiChart) {
      return;
    }
    this.wifiChart.resetZoom();
  }

  resetCellularChartZoom() {
    if (!this.cellularChart) {
      return;
    }
    this.cellularChart.resetZoom();
  }

  refreshWifiGraph() {
    fetch('/admin/wifiSignalStrengthHistoryGraphData/' + this.siteSerialValue + '/' + this.graphDurationValue + '/' + this.missingPollGapValue,
          { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
      .then(response => response.json())
      .then(responseData => {
        if (!responseData) {
          this.wifiSignalStrengthGraphHolderTarget.querySelectorAll('div').forEach(element => {
            this.wifiSignalStrengthGraphHolderTarget.removeChild(element);
          });
          var div = document.createElement('div')
          var h3 = document.createElement('h3')
          div.classList.add('text-center')
          h3.innerHTML = this.noWifiSignalDataValue
          div.appendChild(h3)
          this.wifiSignalStrengthGraphResetButtonTarget.classList.add('d-none');
          this.wifiSignalStrengthGraphHolderTarget.prepend(div);
          if (this.wifiChart) {
            this.wifiChart.destroy();
            this.wifiChart = null;
          }
          return;
        }
        this.wifiSignalStrengthGraphResetButtonTarget.classList.remove('d-none');
        if (this.wifiChart) {
          this.refreshChartData(this.wifiChart, responseData);
        } else {
          this.wifiChart = this.buildGraph(
            this.wifiSignalStrengthGraphHolderTarget,
            this.wifiSignalStrengthGraphTarget,
            [this.wifiGraphNamesValue, this.signalStrengthGuidelineLabelValue],
            responseData,
            'coral',
            'orange'
          );
        }
      })
      .catch(err => { })
  }

  refreshCellularGraph() {
    fetch('/admin/cellularSignalStrengthHistoryGraphData/' + this.siteSerialValue + '/' + this.graphDurationValue + '/' + this.missingPollGapValue,
          { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
      .then(response => response.json())
      .then(responseData => {
        if (!responseData) {
          this.cellularSignalStrengthGraphHolderTarget.querySelectorAll('div').forEach(element => {
            this.cellularSignalStrengthGraphHolderTarget.removeChild(element);
          });
          var div = document.createElement('div')
          var h3 = document.createElement('h3')
          div.classList.add('text-center')
          h3.innerHTML = this.noCellularSignalDataValue
          div.appendChild(h3)
          this.cellularSignalStrengthGraphResetButtonTarget.classList.add('d-none');
          this.cellularSignalStrengthGraphHolderTarget.prepend(div);
          if (this.cellularChart) {
            this.cellularChart.destroy();
            this.cellularChart = null;
          }
          return;
        }
        this.cellularSignalStrengthGraphResetButtonTarget.classList.remove('d-none');
        if (this.cellularChart) {
          this.refreshChartData(this.cellularChart, responseData);
        } else {
          this.cellularChart = this.buildGraph(
            this.cellularSignalStrengthGraphHolderTarget,
            this.cellularSignalStrengthGraphTarget,
            [this.cellularGraphNamesValue, this.signalStrengthGuidelineLabelValue],
            responseData,
            'steelblue',
            'lightskyblue'
          );
        }
      })
      .catch(err => { })
  }

  refreshChartData(chart, data) {
    var firstDate = new Date(Date.now() - (this.graphDurationValue * 60 * 1000));
    chart.data.datasets[0].data = data;
    chart.data.labels = data.map(row => new Date(row.poll_time));
    chart.data.datasets[1].data = [{ poll_time: firstDate, signal: '-90' }, { poll_time: new Date(), signal: '-90'}];
    chart.options.scales.x.min = firstDate;
    chart.update();
  }

  buildGraph(holder, target, labels, signalData, signalDataColour, signalDataOutlineColour) {
    holder.querySelectorAll('div').forEach(element => {
      holder.removeChild(element);
    });
    return this.buildLineGraph(target, signalData, labels, signalDataColour, signalDataOutlineColour);
  }

  buildLineGraph(target, data, labels, signalDataColour, signalDataOutlineColour) {
    var firstDate = new Date(Date.now() - (this.graphDurationValue * 60 * 1000));
    var dodgySignalLineData = [{ poll_time: firstDate, signal: '-90' }, { poll_time: new Date(), signal: '-90'}]
    return new Chart(
      target,
      {
        type: 'line',
        data: {
          labels: data.map(row => new Date(row.poll_time)),
          datasets: [
            {
              label: labels[0],
              data: data,
              parsing: {
                xAxisKey: 'poll_time',
                yAxisKey: 'signal'
              },
              spanGaps: false,
              borderColor: signalDataColour,
              backgroundColor: signalDataOutlineColour,
              tension: 0,
              radius: 0,
              borderWidth: 1.2,
            },
            {
              label: labels[1],
              data: dodgySignalLineData,
              parsing: {
                xAxisKey: 'poll_time',
                yAxisKey: 'signal'
              },
              pointStyle: false,
              pointHitRadius: 0,
              pointHoverRadius: 0,
              pointRadius: 0,
              borderDash: [10,8],
              borderWidth: 1,
              spanGaps: true,
              borderColor: 'rgb(255,0,0)',
              backgroundColor: 'rgb(252,143,143)',
            }
          ]
        },
        options: {
          locale: i18n.locale,
          animation: false,
          parsing: false,
          scales: {
            x: {
              min: firstDate,
              max: new Date(),
              type: 'time',
              ticks: {
                maxRotation: 0,
                autoskip: false,
                major: {
                  enabled: true
                },
                font: function(context) {
                  if (context.tick && context.tick.major) {
                    return {
                      weight: 'bold',
                    };
                  }
                }
              }
            },
            y: {
              min: -140,
              max: 0
            }
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x',
                modifierKey: 'ctrl',
              },
              zoom: {
                drag: {
                  enabled: true
                },
                mode: 'x',
              },
            }
          },
        }
      }
    )
  }
}

