import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="installer-search"
export default class extends Controller {
  static values = {
    webServType: String,
  }
  static targets = [ "findInstallerPage" ]

  async fetchInstallersJSON(setupMapAfter) {
    var response = await fetch('installersList', { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
    var responseData = await response.json()
    this.setupInstallersMap(responseData)
  }

  findInstallerPageTargetConnected(event) {
    this.fetchInstallersJSON()
  }

  setupInstallersMap(installersJSON) {
    if (installersJSON.hasOwnProperty('features')) {
      this.installers = installersJSON['features'];
      this.geocoder = new google.maps.Geocoder()
      this.infoWindow = new google.maps.InfoWindow()
      this.infoWindow.setOptions({pixelOffset: new google.maps.Size(0, -30)})
      var mapCenter = {lat: -28, lng: 135};
      var zoom = 4;
      var sortedInstallers = this.installers.sort(this.sortInstallersBySiteCount)
      if (this.webServTypeValue == 'europe_production') {
        mapCenter = {lat: 40.854033, lng: 20.998634};
        zoom = 5;
      }
      this.mapObject = new google.maps.Map(document.getElementsByClassName('map')[0], {
        zoom: zoom,
        center: mapCenter,
        mapTypeId: 'roadmap',
      })
      this.mapObject.data.addGeoJson(installersJSON, {idPropertyName: 'id'})
      this.loadInstallerInfoPanel(sortedInstallers)

      // Show the information for a store when its marker is clicked.
      this.mapObject.data.addListener('click', event => {
        this.showInstallerInfoOnMap(event.feature)
      })

      this.mapObject.addListener('dragend', event => {
        this.updateInstallersShown(this.mapObject.getCenter())
      })


      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function(position) {
          this.mapObject.setZoom(11)
          this.mapObject.setCenter({lat: position.coords.latitude, lng: position.coords.longitude})
          this.updateInstallersShown(new google.maps.LatLng(position.coords.latitude, position.coords.longitude))
        })
      }
    }
  }

  searchForInstallers(event) {
    if (this.webServTypeValue == 'europe_production') {
      this.updateMapLocation(document.querySelector('#storeLocator').value + ' Italy')
    } else {
      this.updateMapLocation(document.querySelector('#storeLocator').value + ' Australia')
    }
    event.preventDefault()
    return false;
  }

  zoomOnInstaller(event) {
    this.mapObject.setZoom(11)
    var feature = this.mapObject.data.getFeatureById(event.params['installer'])
    if (feature) {
      this.showInstallerInfoOnMap(feature)
    }
  }

  loadInstallerInfoPanel(installers) {
    if (document.getElementById('storeList')) {
      document.getElementById('panel').removeChild(document.getElementById('storeList'))
    }
    if (installers.length == 0) {
      if (document.querySelector('#no-myalarm-stores')) {
        document.querySelector('#no-myalarm-stores').classList.remove('d-none')
      }
      return;
    } else {
      if (document.querySelector('#no-myalarm-stores')) {
        document.querySelector('#no-myalarm-stores').classList.add('d-none')
      }
    }
    var installersList = document.createElement('ul')
    installersList.setAttribute('class', 'store-list')
    installersList.setAttribute('id', 'storeList')
    document.getElementById('panel').appendChild(installersList)
    installers.forEach(function(installer) {
      var newInstallerListHolder = document.createElement('li')
      newInstallerListHolder.setAttribute('class', 'store')
      var newInstallerHolder = document.createElement('div')
      newInstallerHolder.setAttribute('id', 'installer-' + installer['properties']['id']) //TODO: Don't use SQL ID
      newInstallerHolder.setAttribute('data-action', 'click->installer-search#zoomOnInstaller') //TODO: Don't use SQL ID
      newInstallerHolder.setAttribute('data-installer-search-installer-param', installer['properties']['id']) //TODO: Don't use SQL ID
      newInstallerHolder.setAttribute('class', 'store')
      var newInstallerHTML = "";
      if (installer['properties'].hasOwnProperty('logo') && installer['properties']['logo'] !== null && installer['properties']['logo'].trim().length != 0) {
        newInstallerHTML += "<div class='logoHold inline'>";
        newInstallerHTML += "<img class='logo' src='" + installer['properties']['logo'] + "' />";
        newInstallerHTML += "</div>"
        newInstallerHTML += "<div class='installerInfo inline'>";
      } else {
        newInstallerHTML += "<div class='installerInfo'>";
      }
      newInstallerHTML += "<div class='title'>" + installer['properties']['company_name'] + "</div>";
      newInstallerHTML += "<div class='address'>" + installer['properties']['city'] + " " + installer['properties']['state'] + "</div>";
      if (installer['properties']['phone'] !== null && installer['properties']['phone'].trim().length != 0) {
        newInstallerHTML += "<div class='phone'>Phone: " + installer['properties']['phone'] + "</div>";
      }
      if (installer['properties']['email'] !== null && installer['properties']['email'].trim().length != 0) {
        newInstallerHTML += "<div class='email'>Email: " + installer['properties']['email'] + "</div>";
      }
      if (installer['properties']['website'] !== null && installer['properties']['website'].trim().length != 0) {
        newInstallerHTML += "<div class='website'>Website: <a href='https://" + installer['properties']['website'] + "'>" + installer['properties']['website'] + "</a></div>";
      }
      newInstallerHolder.innerHTML = newInstallerHTML;
      newInstallerListHolder.appendChild(newInstallerHolder)
      document.getElementById('storeList').appendChild(newInstallerListHolder)
    })
  }

  async updateMapLocation(newLocation) {
    var result = await this.geocoder.geocode({'address': newLocation})
    if (result.results.length != 0) {
      this.mapObject.setZoom(11)
      this.mapObject.setCenter(result.results[0].geometry.location)
      this.updateInstallersShown(result.results[0].geometry.location)
    }
  }

  updateInstallersShown(center) {
    var installersInRange = this.findInstallersInRangeOfLocation(center, 20)
    if (installersInRange.length === 0) {
      // If we get no installers in 20km, try within 50
      var installersInRange = this.findInstallersInRangeOfLocation(center, 50)
    }
    var sortedInstallers = installersInRange.sort(this.sortInstallersBySiteCount)
    this.loadInstallerInfoPanel(sortedInstallers)
  }

  findInstallersInRangeOfLocation(center, maximum_distance) {
    var installersInRange = [];
    this.installers.forEach(installer => {
      let distance = ( 6371 * Math.acos( Math.cos( this.Math_radians(center.lat()) ) * Math.cos( this.Math_radians( installer['geometry']['coordinates'][1] ) ) * Math.cos( this.Math_radians( installer['geometry']['coordinates'][0] ) - this.Math_radians(center.lng()) ) + Math.sin( this.Math_radians(center.lat()) ) * Math.sin( this.Math_radians( installer['geometry']['coordinates'][1] ) ) ) )
      if (distance < maximum_distance) {
        installersInRange.push(installer)
      }
    })
    return installersInRange;
  }

  showInstallerInfoOnMap(feature) {
    var companyName = feature.getProperty('company_name')
    var city = feature.getProperty('city')
    var state = feature.getProperty('state')
    var phone = feature.getProperty('phone')
    var email = feature.getProperty('email')
    var website = feature.getProperty('website')
    var logo = feature.getProperty('logo')
    var position = feature.getGeometry().get()

    var content = "<div class='store'>";
    if (typeof logo != 'undefined' && logo !== null && logo.trim().length != 0) {
      content += "<div class='logoHold inline'>";
      content += "<img class='logo' src='" + logo + "' />";
      content += "</div><div class='installerInfo inline'>";
    }
    content += "<div class='title'>" + companyName + "</div>";
    content += "<div class='address'>" + city + " " + state + "</div>";
    if (phone !== null && phone.trim().length != 0) {
      content += "<div class='phone'>Phone: " + phone + "</div>";
    }
    if (email !== null && email.trim().length != 0) {
      content += "<div class='email'>Email: " + email + "</div>";
    }
    if (website !== null && website.trim().length != 0) {
      content += "<div class='website'>Website: <a href='https://" + website + "'>" + website + "</a></div>";
    }
    content += '</div>';


    this.infoWindow.setContent(content)
    this.infoWindow.setPosition(position)
    this.mapObject.setZoom(11)
    this.mapObject.setCenter(position)
    this.infoWindow.open(this.mapObject)
  }

    /**
   * Installers who have more sites that are online on a plan are sorted to be
   * first.
   * */
  sortInstallersBySiteCount(a, b) {
    if (a['properties']['sites_count'] < b['properties']['sites_count']) {
      return 1;
    } else if (a['properties']['sites_count'] > b['properties']['sites_count']) {
      return -1;
    }
    return 0
  }

  Math_radians(degrees) {
    return degrees * Math.PI / 180;
  };
}
