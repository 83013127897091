import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import utilsScript from 'intl-tel-input/build/js/utils'

// Connects to data-controller="admin--contact-id-translations"
export default class extends Controller {
  static values = {
    clientCountry: String,
    webServType: String,
  }
  static targets = [
    "phone", "primaryAnswerPoint", "secondaryAnswerPoint", "controlRoomContactPhone",
    "controlRoomAdminPhone", "contactOneOfficePhone", "contactOneMobilePhone",
    "contactTwoOfficePhone", "contactTwoMobilePhone",
    "contactThreeOfficePhone", "contactThreeMobilePhone",
    "contactFourOfficePhone", "contactFourMobilePhone",
    "phoneInput", "primaryAnswerPointInput", "secondaryAnswerPointInput", "controlRoomContactPhoneInput",
    "controlRoomAdminPhoneInput", "contactOneOfficePhoneInput", "contactOneMobilePhoneInput",
    "contactTwoOfficePhoneInput", "contactTwoMobilePhoneInput",
    "contactThreeOfficePhoneInput", "contactThreeMobilePhoneInput",
    "contactFourOfficePhoneInput", "contactFourMobilePhoneInput"
  ]
  onlyCountries = [
    "au", "nz", "it", "gr"
  ]

  connect() {
    this.phoneTargetInput = intlTelInput(this.phoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.primaryAnswerPointTargetInput = intlTelInput(this.primaryAnswerPointTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.secondaryAnswerPointTargetInput = intlTelInput(this.secondaryAnswerPointTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.controlRoomContactPhoneTargetInput = intlTelInput(this.controlRoomContactPhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.controlRoomAdminPhoneTargetInput = intlTelInput(this.controlRoomAdminPhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactOneOfficePhoneTargetInput = intlTelInput(this.contactOneOfficePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactOneMobilePhoneTargetInput = intlTelInput(this.contactOneMobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactTwoOfficePhoneTargetInput = intlTelInput(this.contactTwoOfficePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactTwoMobilePhoneTargetInput = intlTelInput(this.contactTwoMobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactThreeOfficePhoneTargetInput = intlTelInput(this.contactThreeOfficePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactThreeMobilePhoneTargetInput = intlTelInput(this.contactThreeMobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactFourOfficePhoneTargetInput = intlTelInput(this.contactFourOfficePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactFourMobilePhoneTargetInput = intlTelInput(this.contactFourMobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
  }

  markAsGood(target) {
    target.classList.remove('bg-unverified')
    target.classList.add('bg-verified')
  }

  markAsBad(target) {
    target.classList.remove('bg-verified')
    target.classList.add('bg-unverified')
  }

  validatePhoneTarget(event) {
    if (this.phoneTargetInput.isValidNumber() && this.validateBaseStationTranslationNumber(this.phoneTargetInput.getNumber())) {
      this.markAsGood(this.phoneTarget)
      this.phoneInputTarget.value = this.phoneTargetInput.getNumber()
    } else {
      this.markAsBad(this.phoneTarget)
      this.phoneInputTarget.value = ''
    }
  }

  validatePrimaryAnswerPointTarget(event) {
    if (this.primaryAnswerPointTargetInput.isValidNumber() && this.validateBaseStationAnswerPointNumber(this.primaryAnswerPointTargetInput.getNumber())) {
      this.markAsGood(this.primaryAnswerPointTarget)
      this.primaryAnswerPointInputTarget.value = this.primaryAnswerPointTargetInput.getNumber()
    } else {
      this.markAsBad(this.primaryAnswerPointTarget)
      this.primaryAnswerPointInputTarget.value = ''
    }
  }

  validateSecondaryAnswerPointTarget(event) {
    if (this.secondaryAnswerPointTargetInput.isValidNumber() && this.validateBaseStationAnswerPointNumber(this.secondaryAnswerPointTargetInput.getNumber())) {
      this.markAsGood(this.secondaryAnswerPointTarget)
      this.secondaryAnswerPointInputTarget.value = this.secondaryAnswerPointTargetInput.getNumber()
    } else {
      this.markAsBad(this.secondaryAnswerPointTarget)
      this.secondaryAnswerPointInputTarget.value = ''
    }
  }

  validateStandardPhoneOrMobile(input, target, outputTarget) {
    if (input.isValidNumber()) {
      this.markAsGood(target)
      outputTarget.value = input.getNumber()
    } else {
      this.markAsBad(target)
      outputTarget.value = ''
    }
  }

  validateControlRoomContactPhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.controlRoomContactPhoneTargetInput, this.controlRoomContactPhoneTarget, this.controlRoomContactPhoneInputTarget)
  }

  validateControlRoomAdminPhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.controlRoomAdminPhoneTargetInput, this.controlRoomAdminPhoneTarget, this.controlRoomAdminPhoneInputTarget)
  }

  validateContactOneOfficePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactOneOfficePhoneTargetInput, this.contactOneOfficePhoneTarget, this.contactOneOfficePhoneInputTarget)
  }

  validateContactOneMobilePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactOneMobilePhoneTargetInput, this.contactOneMobilePhoneTarget, this.contactOneMobilePhoneInputTarget)
  }

  validateContactTwoOfficePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactTwoOfficePhoneTargetInput, this.contactTwoOfficePhoneTarget, this.contactTwoOfficePhoneInputTarget)
  }

  validateContactTwoMobilePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactTwoMobilePhoneTargetInput, this.contactTwoMobilePhoneTarget, this.contactTwoMobilePhoneInputTarget)
  }

  validateContactThreeOfficePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactThreeOfficePhoneTargetInput, this.contactThreeOfficePhoneTarget, this.contactThreeOfficePhoneInputTarget)
  }

  validateContactThreeMobilePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactThreeMobilePhoneTargetInput, this.contactThreeMobilePhoneTarget, this.contactThreeMobilePhoneInputTarget)
  }

  validateContactFourOfficePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactFourOfficePhoneTargetInput, this.contactFourOfficePhoneTarget, this.contactFourOfficePhoneInputTarget)
  }

  validateContactFourMobilePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactFourMobilePhoneTargetInput, this.contactFourMobilePhoneTarget, this.contactFourMobilePhoneInputTarget)
  }

  validateBaseStationTranslationNumber(numberEntry) {
    var auAreaCodes = ['2', '3', '7', '8'];
    var i = 0;
    var len = numberEntry.length;
    var countryCode = '';
    var areaCode = '';
    var phoneNumber = '';
    var result = false;
    while (i < len) {
      if (i < 3 && countryCode.length < 2 && !(i == 0 && numberEntry[i] == '+')) {
        countryCode = countryCode + numberEntry[i];
      } else if (countryCode == '61') {
        if (areaCode.length == 0) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (phoneNumber.length == 8 && $.inArray(areaCode, auAreaCodes) > -1) {
            return true;
          } else if (phoneNumber.length == 9 && numberEntry[3] == '1' && numberEntry[4] == '3') {
            return true;
          } else if (phoneNumber.length == 6 && numberEntry[3] == '1' && numberEntry[4] == '3' && numberEntry[5] == '4' && numberEntry[6] == '5') {
            return true;
          } else {
            result = false;
          }
        }
      }
      i++;
    }
    return result;
  }

  validateBaseStationAnswerPointNumber(numberEntry) {
    var auAreaCodes = ['2', '3', '7', '8'];
    var nzAreaCodes = ['3', '4', '6', '7', '9'];
    var elAreaCodes = ['21', '22', '23', '24', '25', '26', '27', '28'];
    var i = 0;
    var len = numberEntry.length;
    var countryCode = '';
    var areaCode = '';
    var phoneNumber = '';
    var result = false;
    while (i < len) {
      if (i < 3 && countryCode.length < 2 && !(i == 0 && numberEntry[i] == '+')) {
        countryCode = countryCode + numberEntry[i];
      } else if (countryCode == '61') {
        if (areaCode.length == 0) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (phoneNumber.length == 8 && $.inArray(areaCode, auAreaCodes) > -1) {
            if (phoneNumber[0] == '1' && phoneNumber[1] == '3') {
              return false;
            } else {
              return true;
            }
          } else {
            result = false;
          }
        }
      } else if (countryCode == '64') {
        if (areaCode.length == 0) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (phoneNumber.length == 7 && $.inArray(areaCode, nzAreaCodes) > -1) {
            $('#base_station_number_phoneNumber_out').addClass('bg-verified').removeClass('bg-unverified');
            return true;
          } else {
            result = false;
          }
        }
      } else if (countryCode == '39') {
        if (areaCode.length < 2) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (areaCode.length == 2 && phoneNumber.length >= 5 && phoneNumber.length <= 10) {
            $('#base_station_number_phoneNumber_out').addClass('bg-verified').removeClass('bg-unverified');
            result = true;
          } else {
            result = false;
          }
        }
      } else if (countryCode == '30') {
        if (areaCode.length < 2) {
          areaCode = areaCode + numberEntry[i];
        } else {
          phoneNumber = phoneNumber + numberEntry[i];
          if (areaCode.length == 2 && phoneNumber.length == 8 && $.inArray(areaCode, elAreaCodes) > -1) {
            $('#base_station_number_phoneNumber_out').addClass('bg-verified').removeClass('bg-unverified');
            result = true;
          } else {
            result = false;
          }
        }
      }
      i++;
    }
    return result;
  }

  validatePhoneNumber(event) {

  }
}
