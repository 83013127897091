import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users--installers"
export default class extends Controller {
  static targets = [ "registerInstallerPage" ]

  registerInstallerPageTargetConnected(target) {

  }

  validateLogoFile(event) {
    var allowedExtensions = ['jpg', 'jpeg', 'gif', 'png']
    var file = event.target.files.item(0)
    // We only deal with one file here.
    if (file.size > parseInt(event.params['maxFileSize'])) {
      window.alert(event.params['tooLargeMessage'])
      event.target.value = ''
      return
    }
    if (!allowedExtensions.includes(file.name.split('.').pop())) {
      window.alert(event.params['badFileTypeMessage'])
      event.target.value = ''
      return
    }
  }
}
