import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

// Connects to data-controller="session-statistics"
export default class extends Controller {
  static values = {
    dateChartTitle: String,
    firmwareChartTitle: String,
  }
  static targets = [
    'sessionStatisticsGraphByDate',
    'sessionStatisticsGraphByFirmware',
  ];

  colorOK = 'rgba(29, 211, 109, 0.55)';
  colorError1 = 'rgba(255, 33, 0, 0.55)';
  colorError2 = 'rgba(230, 33, 0, 0.55)';
  colorError3 = 'rgba(205, 33, 0, 0.55)';
  colorError4 = 'rgba(180, 33, 0, 0.55)';
  colorError5 = 'rgba(155, 33, 0, 0.55)';

  connect() {
    fetch('sessionStatisticsGraphData', {
      method: 'get',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
    })
      .then((response) => response.json())
      .then((responseData) => {
        this.sessionStatisticsChartByDate = this.buildGraph(
          this.sessionStatisticsGraphByDateTarget,
          responseData['statistics_by_date'],
          'date_created',
          this.dateChartTitleValue
        );
        this.sessionStatisticsChartByFirmware = this.buildGraph(
          this.sessionStatisticsGraphByFirmwareTarget,
          responseData['statistics_by_firmware'],
          'panel_firmware',
          this.firmwareChartTitleValue
        );
      })
      .catch((err) => {
        // console.log('Error getting session statistics data: ' + err);
      });
  }

  buildGraph(target, data, columnNameKey, graphTitle) {
    // This function assumes that the data comes in this format:
    // [
    //   {[columnNameKey]: '', 
    //   final_status: int, 
    //   session_count: int,
    //   final_status_title: ''
    //   },
    //   {[columnNameKey]: '', 
    //   final_status: int, 
    //   session_count: int,
    //   final_status_title: ''
    //   },
    // ]  
    // There should only be one object for every unique final status, which may
    // require data to be amalgamated beforehand.

    // Format data in usable way by the chart.js
    // Sort the dataset by column, which is defined in columnNameKey
    let datasetsByColumn = [];
    let finalStatusMap = new Map();

    data.forEach((session) => {
      const foundDateObject = datasetsByColumn.find(
        (obj) => obj[columnNameKey] === session[columnNameKey]
      );

      if (foundDateObject) {
        foundDateObject.sessionList.push(session);
      } else {
        let pushObj = {
          [columnNameKey]: session[columnNameKey],
          sessionList: [session],
        };
        datasetsByColumn.push(pushObj);
      }

      if (!finalStatusMap.has(session.final_status)) {
        finalStatusMap.set(session.final_status, session.final_status_title);
      }
    });
    datasetsByColumn.sort((a, b) =>
      a[columnNameKey].localeCompare(b[columnNameKey])
    );

    let columnLabels = [];
    let datasetsByFinalStatus = [];

    // For each day, create an array of all the dates to pass to labels.
    // For each session in a day, check if there is an object with the correct
    // status in formattedDataset

    let finalStatusIterator = finalStatusMap.entries();

    for (let [key, value] of finalStatusIterator) {
      let statusDataSet = {
        label: value,
        final_status: key,
        data: [],
      };

      switch (key) {
        case 1:
          statusDataSet.backgroundColor = this.colorOK;
          break;

        case 18:
          statusDataSet.backgroundColor = this.colorError1;
          break;

        case 8:
          statusDataSet.backgroundColor = this.colorError2;
          break;

        case 14:
          statusDataSet.backgroundColor = this.colorError3;
          break;

        default:
          statusDataSet.backgroundColor = this.colorError4;
      }

      datasetsByFinalStatus.push(statusDataSet);
    }

    datasetsByColumn.forEach((columnObject) => {
      // Counts how many sessions in each day
      let totalDailySessions = 0;

      // Initilises each section of data with 0 to be overwritten later if the
      // final status appears in sessions in that day
      datasetsByFinalStatus.forEach((dataset) => {
        dataset.data.push(0);
      });

      columnObject.sessionList.forEach((session) => {
        totalDailySessions += session.session_count;

        const foundObjWithStatus = datasetsByFinalStatus.find(
          (obj) => obj.final_status === session.final_status
        );

        // If there is an object with the correct status in formattedDataset,
        // replace the previously initialised 0 with correct session count.
        if (foundObjWithStatus) {
          foundObjWithStatus.data[foundObjWithStatus.data.length - 1] =
            session.session_count;
        }
        // If not, add an object with the correct status in formattedDataset
        // else {
        //   console.log("Error: Couldn't find FinalStatus parent object.");
        // }
      });

      // Add session count to be below column name
      columnLabels.push([columnObject[columnNameKey], 'Total: ' + totalDailySessions]);

      // Calculate the percentage that each final_status appeared
      columnObject.percentagesByStatus = new Map();

      columnObject.sessionList.forEach((session) => {
        columnObject.percentagesByStatus.set(
          session.final_status,
          session.session_count / totalDailySessions
        );
      });
    });

    // Move the OK sessions to the bottom of the bar graph
    const sessionToMove = datasetsByFinalStatus.find(
      (obj) => obj.final_status === 1
    );
    if (sessionToMove) {
      datasetsByFinalStatus.splice(datasetsByFinalStatus.indexOf(sessionToMove), 1);
      datasetsByFinalStatus.unshift(sessionToMove);
    }

    // Rename the labels
    datasetsByFinalStatus.forEach((element) => {
      if (element.final_status != 1) {
        element.label = 'ERROR - ' + element.label;
      }
    });

    return new Chart(target, {
      type: 'bar',
      data: {
        labels: columnLabels,
        datasets: datasetsByFinalStatus,
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      options: {
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        },
        plugins: {
          tooltip: {
            callbacks: {
              // Add percentage to the mouse over tooltip
              label: function (context) {
                let foundColumnObjects = [];

                // Find the correct column object which contains the percentage list
                datasetsByColumn.forEach(column => {
                  if (context.label.includes(column[columnNameKey]))
                    foundColumnObjects.push(column);
                })
                const foundColumnObject = foundColumnObjects[0];

                if (foundColumnObject) {
                  let percentage =
                    foundColumnObject.percentagesByStatus.get(
                      context.dataset.final_status
                    ) * 100;
                  percentage = percentage.toFixed(1) + '%';

                  return (
                    context.dataset.label +
                    ': ' +
                    context.raw +
                    ' (' +
                    percentage +
                    ')'
                  );
                } else {
                  return context.label;
                }
              },
            },
          },
          title: {
            display: true,
            text: graphTitle,
            font: {
              size: 24,
            },
          },
        },
        locale: i18n.locale,
      },
    });
  }
}
