import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="admin--sites"
export default class extends Controller {
  static targets = [
    "sitesTable",
    "deleteRuleModal", "deleteRuleModalSubmit",
    "reportingOldTemplateId", "reportingOldProfileRoute", "reportingOldTemplateSelect"
  ]

  deletePersonalReportingRule(event) {
    this.deleteRuleModalSubmitTarget.setAttribute('href', event.params['submitLink']);
    if (!this.deleteRuleModal) {
      this.deleteRuleModal = new bootstrap.Modal(this.deleteRuleModalTarget, { keyboard: false });
    }
    if (this.deleteRuleModal) {
      this.deleteRuleModal.show()
    }
  }

  showHistoryDetails(event) {
    document.querySelectorAll('.history-details').forEach(element => element.classList.toggle('d-none'))
  }

  openSite(event) {
    Turbo.visit(event.params['link'])
  }

  searchSites(event) {
    if (this.hasSitesTableTarget) {
      var searchText = event.target.value
      if (searchText == "") {
        Array.from(this.sitesTableTarget.querySelectorAll('tbody > tr')).filter(row => {
          row.classList.remove('d-none')
        })
        return
      }
      Array.from(this.sitesTableTarget.querySelectorAll('tbody > tr')).filter(row => {
        var rowMatched = false
        row.querySelectorAll('td').forEach(rowColumn => {
          if (!rowMatched && rowColumn.innerHTML.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
            rowMatched = true
          }
        })
        if (rowMatched) {
          row.classList.remove('d-none')
        } else {
          row.classList.add('d-none')
        }
      })
    }
  }

  setupReportingTemplateMigration(event) {
    if (this.hasReportingOldTemplateIdTarget) {
      this.reportingOldTemplateIdTarget.value = event.params['oldTemplate']
    }
    if (this.hasReportingOldProfileRouteTarget) {
      this.reportingOldProfileRouteTarget.value = event.params['oldProfileRoute']
    }
    if (this.hasReportingOldTemplateSelectTarget) {
      this.reportingOldTemplateSelectTarget.value = event.params['oldTemplate']
    }
  }
}
