import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--documentation"
export default class extends Controller {
  static targets = [
    'addGroupForm', 'addGroupButton', 'addEntityForm', 'addEntityButton'
  ]

  connect() {
  }

  showAddGroupForm() {
    if (this.hasAddGroupFormTarget) {
      this.addGroupFormTarget.classList.remove('d-none');
      this.addGroupButtonTarget.classList.add('d-none');
    }
  }

  showAddEntityForm(event) {
    event.target.classList.add('d-none');
    var groupId = event.params['groupId']
    document.getElementById('addEntityForm_' + groupId).classList.remove('d-none');
  }

  showEditEntityForm(event) {
    event.target.classList.add('disabled');
    var groupId = event.params['groupId']
    var entityId = event.params['entityId']
    document.getElementById('editEntityForm_' + groupId + '_' + entityId).classList.remove('d-none');
  }

  hideEditEntityForm(event) {
    var groupId = event.params['groupId']
    var entityId = event.params['entityId']
    document.getElementById('editEntityButton_' + groupId + '_' + entityId).classList.remove('disabled');
    document.getElementById('editEntityForm_' + groupId + '_' + entityId).classList.add('d-none');
  }

  showEditGroupForm(event) {
    event.target.classList.add('disabled');
    var groupId = event.params['groupId']
    document.getElementById('editGroupForm_' + groupId).classList.remove('d-none');
  }

  hideEditGroupForm(event) {
    var groupId = event.params['groupId']
    document.getElementById('editGroupButton_' + groupId).classList.remove('disabled');
    document.getElementById('editGroupForm_' + groupId).classList.add('d-none');
  }
}
