import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="mobiles"
export default class extends Controller {
  static targets = [
    "mobilesTable", "mobilesFormSubmit", "formDeleteMobileIndex",
    "deleteMobileIndex", "deleteMobileName", "deleteMobileModal",
    "reRegisterMobileModal", "formReRegisterMobileIndex",
    "reRegisterMobileIndex", "reRegisterMobileName", "reRegisterMobileModal",
    "resetQuarantineIndex", "resetQuarantineName", "resetQuarantineModal",
    "resetQuarantineSubmit", "newCodeModal"
  ]

  connect() {
    if (this.hasMobilesTableTarget) {
      this.setup()
    }
    if (this.hasNewCodeModalTarget) {
      if (!this.newCodeModal) {
        this.newCodeModal = new bootstrap.Modal(this.newCodeModalTarget, { keyboard: false });
      }
      if (this.newCodeModal) {
        this.newCodeModal.show()
      }
    }
  }

  setup() {

  }

  setupNewMobileRow(event) {
    if (document.getElementById(event.target.id).value.length > 0) {
      var mobileIndex = event.params['mobileIndex']
      document.getElementById('pmb' + mobileIndex + '_wifi_only').checked = false
      document.getElementById('pmb' + mobileIndex + '_pin_always').checked = false
      document.getElementById('pmb' + mobileIndex + '_confirm_all').checked = false
      document.getElementById('pmb' + mobileIndex + '_panic_visible').checked = true
      document.getElementById('pmb' + mobileIndex + '_panic_silent').checked = false
      document.getElementById('pmb' + mobileIndex + '_control_outputs').checked = true
      document.getElementById('pmb' + mobileIndex + '_control_doors').checked = true
      document.getElementById('pmb' + mobileIndex + '_bypass_zones').checked = true
      document.getElementById('pmb' + mobileIndex + '_see_history').checked = true
      document.getElementById('pmb' + mobileIndex + '_get_code').classList.remove('d-none')
    } else if (document.getElementById(event.target.id).value.length == 0) {
      var mobileIndex = event.params['mobileIndex']
      document.getElementById('pmb' + mobileIndex + '_wifi_only').checked = false
      document.getElementById('pmb' + mobileIndex + '_pin_always').checked = false
      document.getElementById('pmb' + mobileIndex + '_confirm_all').checked = false
      document.getElementById('pmb' + mobileIndex + '_panic_visible').checked = false
      document.getElementById('pmb' + mobileIndex + '_panic_silent').checked = false
      document.getElementById('pmb' + mobileIndex + '_control_outputs').checked = false
      document.getElementById('pmb' + mobileIndex + '_control_doors').checked = false
      document.getElementById('pmb' + mobileIndex + '_bypass_zones').checked = false
      document.getElementById('pmb' + mobileIndex + '_see_history').checked = false
      document.getElementById('pmb' + mobileIndex + '_get_code').classList.add('d-none')
    }
  }

  deleteMobile(event) {
    var mobileIndex = event.params['mobileIndex']
    var mobileName = event.params['mobileName']
    if (this.hasDeleteMobileIndexTarget) {
      this.deleteMobileIndexTarget.innerHTML = mobileIndex
      this.deleteMobileNameTarget.innerHTML = mobileName
      this.formDeleteMobileIndexTarget.value = mobileIndex
      if (!this.deleteMobileModal) {
        this.deleteMobileModal = new bootstrap.Modal(this.deleteMobileModalTarget, { keyboard: false });
      }
      if (this.deleteMobileModal) {
        this.deleteMobileModal.show()
      }
    }
  }

  reRegisterMobile(event) {
    var mobileIndex = event.params['mobileIndex']
    var mobileName = event.params['mobileName']
    if (this.hasReRegisterMobileIndexTarget) {
      this.reRegisterMobileIndexTarget.innerHTML = mobileIndex
      this.reRegisterMobileNameTarget.innerHTML = mobileName
      this.formReRegisterMobileIndexTarget.value = mobileIndex
      if (!this.reRegisterMobileModal) {
        this.reRegisterMobileModal = new bootstrap.Modal(this.reRegisterMobileModalTarget, { keyboard: false });
      }
      if (this.reRegisterMobileModal) {
        this.reRegisterMobileModal.show()
      }
    }
  }

  resetMobileQuarantine(event) {
    var siteSerial = event.params['siteSerial']
    var mobileIndex = event.params['mobileIndex']
    var mobileName = event.params['mobileName']
    if (this.hasResetQuarantineIndexTarget) {
      this.resetQuarantineIndexTarget.innerHTML = mobileIndex
      this.resetQuarantineNameTarget.innerHTML = mobileName
      this.resetQuarantineSubmitTarget.href = '/mobiles/' + siteSerial +'/resetQuarantine/' + mobileIndex
      if (!this.resetQuarantineModal) {
        this.resetQuarantineModal = new bootstrap.Modal(this.resetQuarantineModalTarget, { keyboard: false });
      }
      if (this.resetQuarantineModal) {
        this.resetQuarantineModal.show()
      }
    }
  }

  newAppCode(event) {
    var mobileIndex = event.params['mobileIndex']
    var newCode =  Math.floor((Math.random() * (999999 - 100000) + 100000));
    if (document.getElementById('pmb' + mobileIndex + '_registration_code')) {
      document.getElementById('pmb' + mobileIndex + '_registration_code').value = newCode
    } else if (document.getElementById('mb' + mobileIndex + '_registration_code')) {
      document.getElementById('mb' + mobileIndex + '_registration_code').value = newCode
    } else {
      return;
    }
    if (this.hasMobilesFormSubmitTarget) {
      this.mobilesFormSubmitTarget.click()
    }
  }
}
