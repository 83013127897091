import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--contact-id"
export default class extends Controller {
  playCallAudio(event) {
    var audioPlayer = document.getElementById(event.params['audioPlayer']);
    var audioDestination = event.params['audioDestination'];
    if (audioPlayer && audioDestination) {
      audioPlayer.src = audioDestination;
      audioPlayer.load();
      audioPlayer.play();
      event.target.classList.add('d-none');
      audioPlayer.classList.remove('d-none');
    }
  }
}
