import { Controller } from "@hotwired/stimulus"
import * as daterangepicker from 'daterangepicker' // TODO: Find a date picker alternative that isnt jQuery

// Connects to data-controller="sites--history"
export default class extends Controller {
  static values = {
    pickerStrings: Object,
    pickerDayNames: Array,
    pickerMonthNames: Array,
    pickerDateStart: String,
    pickerDateEnd: String,
    pickerHasTime: Boolean
  }
  static targets = [ "dateRangePicker", "submitSearch" ]
  connect() {
    this.setupDateRangePicker();
  }

  clearSearchableText(event) {
    document.querySelectorAll('.clearable_text').forEach(element => element.value = '')
  }

  clearAllCheckboxes(event) {
    this.updateAllCheckboxes(false)
  }

  checkAllCheckboxes(event) {
    this.updateAllCheckboxes(true)
  }

  updateAllCheckboxes(checkedValue) {
    document.querySelectorAll('.checkable').forEach(element => element.checked = checkedValue)
  }

  rangeCheck(event) {
    var ID_RANGE = '[0-9]{1,3}'
    var SINGLE_RANGE = ID_RANGE + '(-' + ID_RANGE + ')?'
    var MULTI_RANGE = '^' + SINGLE_RANGE + '(,' + SINGLE_RANGE + ')*$'
    var range_regexp = new RegExp(MULTI_RANGE)
    if (event.target.value == '' || range_regexp.test(event.target.value)) {
      event.target.classList.remove('bg-unverified');
      event.target.classList.add('bg-verified');
    } else {
      event.target.classList.remove('bg-verified');
      event.target.classList.add('bg-unverified');
    }
    if (document.querySelectorAll('.bg-unverified').length != 0) {
      this.submitSearchTarget.disabled = true
    } else {
      this.submitSearchTarget.disabled = false
    }
  }

  setupDateRangePicker() {
    if (this.hasDateRangePickerTarget) {
      var currentTime = new Date();

      var thisDay = currentTime.getDate();
      var thisMonth = currentTime.getMonth();
      var thisYear = currentTime.getFullYear();
      var thisHour = currentTime.getHours();
      var thisMinute = currentTime.getMinutes();

      // Object to hold ranges with translation
      var ourRanges = {};

      var todayMinutes = new Date (thisYear,thisMonth,thisDay,thisHour,thisMinute);
      var back24 = new Date (thisYear,thisMonth,thisDay,thisHour-24,thisMinute);
      ourRanges[this.pickerStringsValue['last24']] = [back24, todayMinutes];

      var back48 = new Date (thisYear,thisMonth,thisDay,thisHour-48,thisMinute);
      ourRanges[this.pickerStringsValue['last48']] = [back48, todayMinutes];

      var todayStart = new Date (thisYear,thisMonth,thisDay);
      var todayEnd = new Date (thisYear,thisMonth,thisDay+1,0,0,0,-1);
      ourRanges[this.pickerStringsValue['today']] = [todayStart, todayEnd];

      var yesterdayStart = new Date (thisYear,thisMonth,thisDay-1);
      var yesterdayEnd = new Date (thisYear,thisMonth,thisDay,0,0,0,-1);
      ourRanges[this.pickerStringsValue['yesterday']] = [yesterdayStart, yesterdayEnd];

      var monthStart = new Date (thisYear,thisMonth,1);
      var monthEnd = new Date (thisYear,thisMonth+1,1,0,0,0,-1);
      ourRanges[this.pickerStringsValue['this_month']] = [monthStart, monthEnd];

      var yestermonthStart = new Date (thisYear,thisMonth-1,1);
      var yestermonthEnd = new Date (thisYear,thisMonth,1,0,0,0,-1);
      ourRanges[this.pickerStringsValue['last_month']] = [yestermonthStart, yestermonthEnd];

      var yearStart = new Date (thisYear,0,1);
      var yearEnd = new Date (thisYear+1,0,1,0,0,0,-1);
      ourRanges[this.pickerStringsValue['this_year']] = [yearStart, yearEnd];

      var yesteryearStart = new Date (thisYear-1,0,1,0,0,0,0);
      var yesteryearEnd = new Date (thisYear,0,1,0,0,0,-1);
      ourRanges[this.pickerStringsValue['last_year']] = [yesteryearStart, yesteryearEnd];

      $('.date-range').daterangepicker({
        "autoApply": true,
        "timePicker": this.pickerHasTimeValue,
        "timePicker24Hour": true,
        "timePickerIncrement": 5,
        "locale": {
          "format": this.pickerHasTimeValue ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY",
          "separator": " - ",
          "applyLabel": this.pickerStringsValue['apply'],
          "cancelLabel": this.pickerStringsValue['cancel'],
          "customRangeLabel": this.pickerStringsValue['custom'],
          "weekLabel": "W",
          "daysOfWeek": this.pickerDayNamesValue,
          "monthNames": this.pickerMonthNamesValue,
          "firstDay": 0
        },
        "ranges": ourRanges,
        "minDate": "01/01/1991 00:00",
        "startDate": this.pickerDateStartValue,
        "endDate": this.pickerDateEndValue
      })
    }
  }
}
