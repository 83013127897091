import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import 'chartjs-adapter-luxon'
import zoomPlugin from 'chartjs-plugin-zoom'
Chart.register(zoomPlugin);

// Connects to data-controller="admin--plans-graphing"
export default class extends Controller {
  static values = {
    dataUrl: String,
  };
  static targets = [
    "onlineStatesStatsGraph", "connectionPathStatsGraph", "basePlanStatsGraph",
    "basePlanOptionStatsGraph",
    "ifobControlStatsGraph", "reportingLiteStatsGraph", "reportingLiteExtrasStatsGraph",
    "reportingLitePlusStatsGraph", "reportingLitePlusExtrasStatsGraph",
    "backToBaseStatsGraph", "backToBaseExtrasStatsGraph",
    "reportingLitePlusSimStatsGraph", "reportingLitePlusSimExtrasStatsGraph",
  ]

  connect() {
    if (this.hasDataUrlValue) {
      fetch(this.dataUrlValue,
            { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
        .then(response => response.json())
        .then(responseData => {
          this.panelsOnlineChart = this.buildOnlineStatsGraphs(responseData);
          this.connectionStatsChart = this.buildConnectionStatsGraphs(responseData);
          this.basePlansChart = this.buildBasePlanStatsGraphs(responseData);
          this.basePlanOptionsChart = this.buildBasePlanOptionStatsGraphs(responseData);
          this.ifobControlPlansChart = this.buildIfobControlStatsGraphs(responseData);
          this.reportingLitePlanChart = this.buildReportingLiteStatsGraphs(responseData);
          this.reportingLitePlanExtrasChart = this.buildReportingLiteWithPlanOptionsStatsGraphs(responseData);
          this.reportingLitePlusPlanChart = this.buildReportingLitePlusStatsGraphs(responseData);
          this.reportingLitePlusPlanExtrasChart = this.buildReportingLitePlusWithPlanOptionsStatsGraphs(responseData);
          this.backToBasePlanChart = this.buildBackToBaseStatsGraphs(responseData);
          this.backToBasePlanExtrasChart = this.buildBackToBaseWithPlanOptionsStatsGraphs(responseData);
          this.reportingLitePlusSimPlanChart = this.buildReportingLitePlusSimStatsGraphs(responseData);
          this.reportingLitePlusSimPlanExtrasChart = this.buildReportingLitePlusSimWithPlanOptionsStatsGraphs(responseData);
        })
        .catch(err => { console.log(err);})
    }
  }

  buildOnlineStatsGraphs(data) {
    return this.buildGraph(
      this.onlineStatesStatsGraphTarget,
      data,
      [
        'Online Panels', 'Offline Panels', 'Panels in Poll Fail'
      ],
      [
        'online_panels', 'offline_panels', 'pollfail_panels'
      ],
      [
        'green', 'orange', 'red'
      ]
    );
  }

  buildConnectionStatsGraphs(data) {
    return this.buildGraph(
      this.connectionPathStatsGraphTarget,
      data,
      [
        'Ethernet/WiFi Panels', 'Cellular Panels', 'Ethernet/WiFi + Cellular Panels'
      ],
      [
        'ethernet_panels', 'cellular_panels', 'ethernet_and_cellular_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildBasePlanStatsGraphs(data) {
    return this.buildGraph(
      this.basePlanStatsGraphTarget,
      data,
      [
        'No Plan Panels', 'iFob Control Panels', 'Reporting Lite Panels',
        'Reporting Lite Plus Panels', 'Back to Base SIM Panels', 'Reporting Lite Plus SIM Panels'
      ],
      [
        'no_plan_panels', 'ifob_control_panels', 'reporting_lite_panels',
        'reporting_lite_plus_panels', 'back_to_base_panels', 'reporting_lite_plus_sim_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildBasePlanOptionStatsGraphs(data) {
    return this.buildGraph(
      this.basePlanOptionStatsGraphTarget,
      data,
      [
        'No Plan Option Panels', 'One Hour Poll Fail Panels', 'Advanced Personal Reporting Panels',
        'Dual SIM Panels', 'Additional Mobile Users and Personal Reporting Rules Panels', 'Emergency Voice Panels'
      ],
      [
        'no_plan_option_panels', 'one_hour_pollfail_panels', 'adv_personal_rules_panels',
        'dual_sim_panels', 'extra_users_and_personal_rules_panels', 'emergency_voice_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildIfobControlStatsGraphs(data) {
    return this.buildGraph(
      this.ifobControlStatsGraphTarget,
      data,
      [
        'iFob Control Monthly', 'iFob Control Yearly', 'iFob Control Pay Once', 'Upgraded from iFob Control Pay Once'
      ],
      [
        'ifob_control_monthly_panels', 'ifob_control_yearly_panels', 'ifob_control_unlimited_panels', 'ifob_control_unlimited_upgraded_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildReportingLiteStatsGraphs(data) {
    return this.buildGraph(
      this.reportingLiteStatsGraphTarget,
      data,
      [
        'Reporting Lite Monthly', 'Reporting Lite Yearly'
      ],
      [
        'reporting_lite_monthly_panels', 'reporting_lite_yearly_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildReportingLiteWithPlanOptionsStatsGraphs(data) {
    return this.buildGraph(
      this.reportingLiteExtrasStatsGraphTarget,
      data,
      [
        'Reporting Lite - One Hour Poll Fail', 'Reporting Lite - Advanced Personal Reporting',
        'Reporting Lite - Additional Mobile Users and Personal Reporting Rules'
      ],
      [
        'reporting_lite_with_one_hour_pollfail_panels', 'reporting_lite_with_adv_personal_rules_panels',
        'reporting_lite_with_extra_users_and_personal_rules_panels',
      ],
      [
        '', '', ''
      ]
    );
  }

  buildReportingLitePlusStatsGraphs(data) {
    return this.buildGraph(
      this.reportingLitePlusStatsGraphTarget,
      data,
      [
        'Reporting Lite Plus Monthly', 'Reporting Lite Plus Yearly'
      ],
      [
        'reporting_lite_plus_monthly_panels', 'reporting_lite_plus_yearly_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildReportingLitePlusWithPlanOptionsStatsGraphs(data) {
    return this.buildGraph(
      this.reportingLitePlusExtrasStatsGraphTarget,
      data,
      [
        'Reporting Lite Plus - One Hour Poll Fail', 'Reporting Lite Plus - Advanced Personal Reporting',
        'Reporting Lite Plus - Additional Mobile Users and Personal Reporting Rules',
        'Reporting Lite Plus - Emergency Voice'
      ],
      [
        'reporting_lite_plus_with_one_hour_pollfail_panels', 'reporting_lite_plus_with_adv_personal_rules_panels',
        'reporting_lite_plus_with_extra_users_and_personal_rules_panels',
        'reporting_lite_plus_with_emergency_voice_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildBackToBaseStatsGraphs(data) {
    return this.buildGraph(
      this.backToBaseStatsGraphTarget,
      data,
      [
        'Back to Base Monthly', 'Back to Base Yearly'
      ],
      [
        'back_to_base_monthly_panels', 'back_to_base_yearly_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildBackToBaseWithPlanOptionsStatsGraphs(data) {
    return this.buildGraph(
      this.backToBaseExtrasStatsGraphTarget,
      data,
      [
        'Back to Base SIM - One Hour Poll Fail', 'Back to Base SIM - Dual SIM'
      ],
      [
        'back_to_base_with_one_hour_pollfail_panels', 'back_to_base_with_dualsim_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildReportingLitePlusSimStatsGraphs(data) {
    return this.buildGraph(
      this.reportingLitePlusSimStatsGraphTarget,
      data,
      [
        'Reporting Lite Plus SIM Monthly', 'Reporting Lite Plus SIM Yearly'
      ],
      [
        'reporting_lite_plus_sim_monthly_panels', 'reporting_lite_plus_sim_yearly_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildReportingLitePlusSimWithPlanOptionsStatsGraphs(data) {
    return this.buildGraph(
      this.reportingLitePlusSimExtrasStatsGraphTarget,
      data,
      [
        'Reporting Lite Plus SIM - One Hour Poll Fail',
        'Reporting Lite Plus SIM - Advanced Personal Reporting',
        'Reporting Lite Plus SIM - Dual SIM',
        'Reporting Lite Plus SIM - Additional Mobile Users and Personal Reporting Rules',
        'Reporting Lite Plus SIM - Emergency Voice'
      ],
      [
        'reporting_lite_plus_sim_with_one_hour_pollfail_panels',
        'reporting_lite_plus_sim_with_adv_personal_rules_panels',
        'reporting_lite_plus_sim_with_dualsim_panels',
        'reporting_lite_plus_sim_with_extra_users_and_personal_rules_panels',
        'reporting_lite_plus_sim_with_emergency_voice_panels'
      ],
      [
        '', '', ''
      ]
    );
  }

  buildGraph(target, data, labels, yAxisDataKeys, colours) {
    var datasets = []
    labels.forEach((key, index) => {
      datasets.push({
        label: labels[index],
        data: data,
        parsing: {
          xAxisKey: 'created',
          yAxisKey: yAxisDataKeys[index]
        },
        borderColor: colours[index],
        backgroundColor: colours[index]
      })
    })
    return new Chart(
      target,
      {
        type: 'line',
        data: {
          labels: data.map(row => new Date(row.created)),
          datasets: datasets
        },
        options: {
          locale: i18n.locale,
          interaction: {
            mode: 'point',
            axis: 'xy',
            intersect: false
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day'
              },
              ticks: {
                major: {
                  enabled: true
                },
                font: function(context) {
                  if (context.tick && context.tick.major) {
                    return {
                      weight: 'bold',
                    };
                  }
                }
              }
            }
          }
        }
      }
    )
  }
}
