import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import utilsScript from 'intl-tel-input/build/js/utils'

// Connects to data-controller="admin--myalarm-sia"
export default class extends Controller {
  static values = {
    clientCountry: String,
    webServType: String,
  }
  static targets = [
    "controlRoomContactPhone",
    "controlRoomAdminPhone", "contactOneOfficePhone", "contactOneMobilePhone",
    "contactTwoOfficePhone", "contactTwoMobilePhone",
    "contactThreeOfficePhone", "contactThreeMobilePhone",
    "contactFourOfficePhone", "contactFourMobilePhone",
    "controlRoomContactPhoneInput",
    "controlRoomAdminPhoneInput", "contactOneOfficePhoneInput", "contactOneMobilePhoneInput",
    "contactTwoOfficePhoneInput", "contactTwoMobilePhoneInput",
    "contactThreeOfficePhoneInput", "contactThreeMobilePhoneInput",
    "contactFourOfficePhoneInput", "contactFourMobilePhoneInput"
  ]
  onlyCountries = [
    "au", "nz", "it", "gr"
  ]

  connect() {
    this.controlRoomContactPhoneTargetInput = intlTelInput(this.controlRoomContactPhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.controlRoomAdminPhoneTargetInput = intlTelInput(this.controlRoomAdminPhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactOneOfficePhoneTargetInput = intlTelInput(this.contactOneOfficePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactOneMobilePhoneTargetInput = intlTelInput(this.contactOneMobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactTwoOfficePhoneTargetInput = intlTelInput(this.contactTwoOfficePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactTwoMobilePhoneTargetInput = intlTelInput(this.contactTwoMobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactThreeOfficePhoneTargetInput = intlTelInput(this.contactThreeOfficePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactThreeMobilePhoneTargetInput = intlTelInput(this.contactThreeMobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactFourOfficePhoneTargetInput = intlTelInput(this.contactFourOfficePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
    this.contactFourMobilePhoneTargetInput = intlTelInput(this.contactFourMobilePhoneTarget, {
      initialCountry: this.clientCountryValue,
      onlyCountries: this.onlyCountries,
      placeholderNumberType: 'FIXED_LINE_OR_MOBILE',
      allowDropdown: true,
      customContainer: 'w-100'
    })
  }

  markAsGood(target) {
    target.classList.remove('bg-unverified')
    target.classList.add('bg-verified')
  }

  markAsBad(target) {
    target.classList.remove('bg-verified')
    target.classList.add('bg-unverified')
  }

  validateStandardPhoneOrMobile(input, target, outputTarget) {
    if (input.isValidNumber()) {
      this.markAsGood(target)
      outputTarget.value = input.getNumber()
    } else {
      this.markAsBad(target)
      outputTarget.value = ''
    }
  }

  validateControlRoomContactPhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.controlRoomContactPhoneTargetInput, this.controlRoomContactPhoneTarget, this.controlRoomContactPhoneInputTarget)
  }

  validateControlRoomAdminPhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.controlRoomAdminPhoneTargetInput, this.controlRoomAdminPhoneTarget, this.controlRoomAdminPhoneInputTarget)
  }

  validateContactOneOfficePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactOneOfficePhoneTargetInput, this.contactOneOfficePhoneTarget, this.contactOneOfficePhoneInputTarget)
  }

  validateContactOneMobilePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactOneMobilePhoneTargetInput, this.contactOneMobilePhoneTarget, this.contactOneMobilePhoneInputTarget)
  }

  validateContactTwoOfficePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactTwoOfficePhoneTargetInput, this.contactTwoOfficePhoneTarget, this.contactTwoOfficePhoneInputTarget)
  }

  validateContactTwoMobilePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactTwoMobilePhoneTargetInput, this.contactTwoMobilePhoneTarget, this.contactTwoMobilePhoneInputTarget)
  }

  validateContactThreeOfficePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactThreeOfficePhoneTargetInput, this.contactThreeOfficePhoneTarget, this.contactThreeOfficePhoneInputTarget)
  }

  validateContactThreeMobilePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactThreeMobilePhoneTargetInput, this.contactThreeMobilePhoneTarget, this.contactThreeMobilePhoneInputTarget)
  }

  validateContactFourOfficePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactFourOfficePhoneTargetInput, this.contactFourOfficePhoneTarget, this.contactFourOfficePhoneInputTarget)
  }

  validateContactFourMobilePhoneTarget(event) {
    this.validateStandardPhoneOrMobile(this.contactFourMobilePhoneTargetInput, this.contactFourMobilePhoneTarget, this.contactFourMobilePhoneInputTarget)
  }
}
