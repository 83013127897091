import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="users--validations"
export default class extends Controller {
  static targets = [ "password", "passwordConfirmation", "email", "emailConfirmation", "mobileValidationModal" ]
  connect() {
    if (this.hasPasswordTarget) {
      this.passwordTarget.classList.add('bg-unverified')
    }
    if (this.hasPasswordConfirmationTarget) {
      this.passwordConfirmationTarget.classList.add('bg-unverified')
    }
    if (this.hasEmailConfirmationTarget) {
      this.emailConfirmationTarget.classList.add('bg-unverified')
    }
  }

  mobileValidationModalTargetConnected() {
    if (!this.mobileValidationModal) {
      this.mobileValidationModal = new bootstrap.Modal(this.mobileValidationModalTarget, { keyboard: false });
    }
  }

  passwordKeyUp(event) {
    if (this.passwordComplex(event.target.value)) {
      event.target.classList.remove('bg-unverified')
      event.target.classList.add('bg-verified')
      event.target.setCustomValidity('')
      this.passwordConfirmationTarget.classList.remove('bg-verified')
      this.passwordConfirmationTarget.classList.add('bg-unverified')
    } else {
      event.target.classList.remove('bg-verified')
      event.target.classList.add('bg-unverified')
      event.target.setCustomValidity(event.params['complexity'])
    }
  }

  passwordConfirmationKeyUp(event) {
    if (this.passwordComplex(this.passwordTarget.value) && (this.passwordTarget.value == this.passwordConfirmationTarget.value)) {
      this.passwordConfirmationTarget.classList.add('bg-verified')
      this.passwordConfirmationTarget.classList.remove('bg-unverified')
      event.target.setCustomValidity('')
    } else {
      this.passwordConfirmationTarget.classList.remove('bg-verified')
      this.passwordConfirmationTarget.classList.add('bg-unverified')
      event.target.setCustomValidity(event.params['match'])
    }
  }

  emailConfirmationKeyUp(event) {
    if (this.emailTarget.value != '' && this.emailTarget.value == this.emailConfirmationTarget.value) {
      this.emailConfirmationTarget.classList.add('bg-verified')
      this.emailConfirmationTarget.classList.remove('bg-unverified')
      this.emailTarget.setCustomValidity('')
      this.emailConfirmationTarget.setCustomValidity('')
    } else {
      this.emailConfirmationTarget.classList.remove('bg-verified')
      this.emailConfirmationTarget.classList.add('bg-unverified')
      event.target.setCustomValidity(event.params['match'])
    }
  }

  // Password Helper Functions
  hasDigits(value) {
    return (value.match(/\d/) ? 1 : 0);
  }

  hasAlpha(value) {
    return (value.match(/[A-Za-z]{1}/) ? 1 : 0);
  }

  hasSymbols(value) {
    return (value.match(/[^a-zA-Z\d\s:]/) ? 1 : 0);
  }

  passwordComplex(value) {
    let score = this.hasDigits(value) + this.hasAlpha(value) + this.hasSymbols(value);
    return value.length >= 8 && score >= 2;
  }
}
