import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import 'chartjs-adapter-luxon'
import zoomPlugin from 'chartjs-plugin-zoom'
Chart.register(zoomPlugin);

// Connects to data-controller="admin--invoicing-graphing"
export default class extends Controller {
  static targets = [
    "invoicingStatsGraph"
  ]

  connect() {
    fetch('invoicingStatisticsGraphData',
          { method: 'get', headers: { 'Content-Type': 'application/json;charset=utf-8'} })
      .then(response => response.json())
      .then(responseData => {
        responseData.forEach(row => {
          row.created = Date.parse(row.year + '-' + row.month + '-' + '01')
        });
        this.invoicingChart = this.buildGraph(
          this.invoicingStatsGraphTarget,
          responseData,
          [
            'Invoices', 'Invoiced Sites', 'Sites with First Invoice',
            'iFob Control Monthly', 'Reporting Lite Monthly',
            'Reporting Lite Plus Monthly', 'Back to Base SIM Monthly',
            'Reporting Lite Plus SIM Monthly',
            'iFob Control Yearly', 'Reporting Lite Yearly',
            'Reporting Lite Plus Yearly', 'Back to Base SIM Yearly',
            'Reporting Lite Plus SIM Yearly',
            'iFob Control Pay Once',
          ],
          [
            'total_invoices', 'total_invoiced_sites', 'total_initial_site_invoice',
            'total_sites_on_plan_1_monthly', 'total_sites_on_plan_2_monthly',
            'total_sites_on_plan_3_monthly', 'total_sites_on_plan_4_monthly',
            'total_sites_on_plan_5_monthly',
            'total_sites_on_plan_1_yearly', 'total_sites_on_plan_2_yearly',
            'total_sites_on_plan_3_yearly', 'total_sites_on_plan_4_yearly',
            'total_sites_on_plan_5_yearly',
            'total_sites_on_ifob_unlimited',
          ]
        );
      })
      .catch(err => {})
  }

  buildGraph(target, data, labels, yAxisDataKeys) {
    var datasets = []
    labels.forEach((key, index) => {
      datasets.push({
        label: labels[index],
        data: data,
        parsing: {
          xAxisKey: 'created',
          yAxisKey: yAxisDataKeys[index]
        },
        radius: 0,
      })
    })
    return new Chart(
      target,
      {
        type: 'line',
        data: {
          labels: data.map(row => row.created),
          datasets: datasets
        },
        options: {
          locale: i18n.locale,
          animation: false,
          parsing: false,
          interaction: {
            mode: 'point',
            axis: 'xy',
            intersect: false
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'month'
              },
              ticks: {
                major: {
                  enabled: true
                },
                font: function(context) {
                  if (context.tick && context.tick.major) {
                    return {
                      weight: 'bold',
                    };
                  }
                }
              }
            }
          }
        }
      }
    )
  }
}

