import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="users--users"
export default class extends Controller {
  static targets = [ "multiFactorAuthenticationEnabled", "multiFactorAuthenticationType",
                     "migrateToCompanyAccountModal" ]


  toggleMultiFactorAuthentication(event) {
    if (!this.hasMultiFactorAuthenticationEnabledTarget) {
      return
    }
    this.multiFactorAuthenticationTypeTarget.disabled = this.multiFactorAuthenticationEnabledTarget.value == 0;
  }

  migrateToCompanyAccountModal() {
    if (this.hasMigrateToCompanyAccountModalTarget) {
      if (!this.migrateToCompanyAccountBootstrapModal) {
        this.migrateToCompanyAccountBootstrapModal = new bootstrap.Modal(this.migrateToCompanyAccountModalTarget, { keyboard: false });
      }
      if (this.migrateToCompanyAccountBootstrapModal) {
        this.migrateToCompanyAccountBootstrapModal.show()
      }
    }
  }
}
